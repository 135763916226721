/* eslint-disable no-console */
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  getAuth,
  //sendEmailVerification,
  onAuthStateChanged,
} from 'firebase/auth';

import { Storage } from '@ionic/storage';
import { isPlatform } from '@ionic/vue';
import axios from '../../../boot/axios';

import { getNativeAuth, nativeLogin, webLogin } from '../../support/Auth';
import {
  getPreferencesObject,
  setPreferencesObject,
} from '../../../helpers/preferencesHelper';
import { Device } from '@capacitor/device';

const store = new Storage();

export default {
  //context
  async register(context, { email, password, name }) {
    try {
      const auth = getNativeAuth();
      const response = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      auth.currentUser = response.user;
      try {
        await updateProfile(response.user, { displayName: name });
      } catch (e) {
        console.log('error', e);
      }

      //Autentico ricevuto il token con il server dantebus
      return await context.dispatch('authenticateFirebaseToken', {
        userAccessToken: response.user.accessToken,
      });
    } catch (e) {
      console.log('ERROR', e);
      return {
        status: false,
        error_code: e.code,
      };
    }
  },
  async login(context, { email, password }) {
    try {
      const auth = await getNativeAuth();
      const response = await signInWithEmailAndPassword(auth, email, password);
      //Autentico ricevuto il token con il server dantebus
      const result = await context.dispatch('authenticateFirebaseToken', {
        userAccessToken: response.user.accessToken,
      });

      auth.currentUser = response.user;
      return result;
    } catch (e) {
      console.log('ERROR', e);
      return {
        status: false,
        error_code: e.code,
      };
    }
  },
  async loginWithProvider(context, provider_name) {
    const is_mobile_platform =
      (isPlatform('android') || isPlatform('ios')) && !isPlatform('mobileweb');
    const auth = await getNativeAuth();
    console.log('auth', auth);
    const firebase_credentials = is_mobile_platform
      ? await nativeLogin(provider_name)
      : await webLogin(provider_name);
    console.log('firebase_credentials', firebase_credentials);
    if (firebase_credentials.status === true) {
      const result = await context.dispatch('authenticateFirebaseToken', {
        userAccessToken: firebase_credentials.response.user.accessToken,
      });
      auth.currentUser = firebase_credentials.response.user;
      return result;
    } else {
      console.log('firebase_credentials error', firebase_credentials);
      return {
        status: false,
        error_code: firebase_credentials.error_code,
      };
    }
  },
  async authenticateFirebaseToken(context, { userAccessToken }) {
    const { platform } = await Device.getInfo();
    const { uuid } = await Device.getId();
    const response = await axios.post('/auth/login', {
      token: userAccessToken,
      uuid,
      platform,
    });
    if (response.data.status === false) return { status: false };
    /* Imposto il token del server nello store */
    await store.create();
    await store.set('dantebus-jwt', response.data.jwt);

    axios.interceptors.request.use(async (config) => {
      config.headers['Authorization'] = `Bearer ${response.data.jwt.token}`;
      return config;
    });

    context.commit('setUser', response.data.user); //user.id
    context.commit('setClaims', response.data.claims); //claims
    context.commit('setEmailVerified', response.data.emailVerified);
    context.commit('setNode', response.data.node); //node._id
    context.commit('setBlockedUsersIds', response.data.blocked_users);
    context.commit('setChatUnread', response.data.chat_unread);
    context.commit('setIsFirstLogin', response.data.is_first_login);
    if (response.data.node.profile) {
      if (typeof response.data.node.profile === 'object') {
        context.commit('setProfile', response.data.node.profile);
      } else {
        context.commit('setProfile', JSON.parse(response.data.node.profile));
      }
    }
    context.commit('setCounts', response.data.user_counts);

    return response.data;
  },
  async checkStoredToken(context) {
    await store.create();
    const jwt = await store.get('dantebus-jwt');

    axios.interceptors.request.use(async (config) => {
      if (jwt) {
        config.headers['Authorization'] = `Bearer ${jwt.token}`;
      }
      return config;
    });

    try {
      const { platform } = await Device.getInfo();
      const { uuid } = await Device.getId();
      const response = await axios.get('/auth/check', {
        params: { uuid, platform },
      });
      if (response.data.status === false) return false;

      context.commit('setUserHasActiveApp', response.data.user_has_active_app);
      context.commit('setUser', response.data.user);
      context.commit('setClaims', response.data.claims);
      context.commit('setEmailVerified', response.data.emailVerified);
      context.commit('setNode', response.data.node);
      context.commit('setBlockedUsersIds', response.data.blocked_users);
      context.commit('setChatUnread', response.data.chat_unread);
      if (response.data.node.profile) {
        context.commit('setProfile', response.data.node.profile);
      }
      context.commit('setCounts', response.data.user_counts);
      //this.$ws.withJwtToken(jwt.token).connect()
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async saveStoredToken(context, { token }) {
    await store.create();
    await store.set('dantebus-jwt', token);
    return true;
  },
  checkEmailVerified(context) {
    return new Promise((resolve) => {
      const auth = getAuth();
      if (auth.currentUser) {
        auth.currentUser.reload().then(() => {
          if (auth.currentUser.emailVerified === true) {
            context.commit('setEmailVerified', true);
          }
          return resolve(true);
        });
      } else {
        onAuthStateChanged(auth, async (user) => {
          await user.reload();
          if (!user) return resolve(false);

          if (user.emailVerified === true) {
            context.commit('setEmailVerified', true);
          }

          return resolve(true);
        });
      }
    });
  },
  async sendVerificationEmail() {
    try {
      const response = await axios.get('/auth/sendVerificationEmail');
      return response.data.status;
    } catch (e) {
      return false;
    }
  },
  async logout(context) {
    await axios.get('/auth/logout');
    await store.create();
    const currentVersion = await getPreferencesObject('current_code_version');
    await store.clear();
    if (isPlatform('capacitor')) {
      await setPreferencesObject('current_code_version', currentVersion);
      console.log('Versione Memorizzata', currentVersion);
    }

    context.commit('resetState');
    context.commit('userNotification/resetNotifications', null, { root: true });
    const auth = getNativeAuth();
    if (auth.currentUser) {
      await auth.signOut();
    }
  },
  /*
      Aggiornamento della password dell'utente, data una vecchia password e la nuova.
     */
  async passwordUpdate(context, { oldPassword, password }) {
    const response = await axios.post('/user/passwordUpdate', {
      oldPassword,
      password,
    });
    return response.data;
  },

  /*
      Aggiornamento dell'immagine di profilo o di copertina
      imageType può essere avatar | backdrop
      peso massimo consentito: 25mb
      estensioni consentite: jpg, png, bmp, gif

      La funzione aggiorna automaticamente lo store e torna boolean
     */
  async updateImage(context, { imageType, image }) {
    const formData = new FormData();
    formData.append('image', image);
    const response = await axios.post(
      '/user/updateImage/' + imageType,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    if (response.data.status === false) return false;

    context.commit('setUser', response.data.user);
    context.commit('setNode', response.data.node);

    return true;
  },

  //Forgot Password
  /* Funzione che invia l'email
    Ritorna: boolean
     */
  async sendRecoverToken(context, { email }) {
    const response = await axios.post('/auth/forgotPassword', { email });
    return response.data.status;
  },

  /* Funzione che verifica il token, torna un bool
      Ritorna: status (boolean) e eventuale error (se status === false)
       */
  async checkRecoverToken(context, { email, token }) {
    const response = await axios.post(
      '/auth/forgotPassword/checkToken/' + token,
      { email }
    );
    return response.data;
  },

  /* Funzione che verifica il token e aggiorna la password
      Ritorna: status (boolean) e eventuale error (se status === false)
       */
  async recoverPasswordUpdate(context, { email, token, newPassword }) {
    const response = await axios.post('/auth/forgotPassword/' + token, {
      email,
      newPassword,
    });
    return response.data;
  },

  //Followers/Following

  /*
   * Funzione che preleva un elenco di utenti.
   *  type: obbligatorio, followers|following
   *  user_id: opzionale, se non inserito viene utilizzato l'id dell'utente loggato
   *  page: obbligatorio
   * */

  async getFollowersOrFollowing(context, { type, user_id, page }) {
    const response = await axios.get(
      '/user/relation/' + type + (user_id ? '/' + user_id : ''),
      { params: { page } }
    );
    if (response.data.status === false) return false;

    return response.data.users; //TODO Integrare nello state
  },

  //Profilo utente
  async updateProfile(context, { fields, signal }) {
    const response = await axios.post('/user/profile', fields, { signal });
    if (response.data.status === true) {
      context.commit('setProfile', fields);
      return true;
    } else {
      return false;
    }
  },

  async updateIsFirstLogin(context, { isFirstLogin }) {
    context.commit('setIsFirstLogin', isFirstLogin);
  },

  //Like di un post
  async likePost(context, post_id) {
    //Avvio la richiesta
    const response = await axios.get('/user/likes/post/' + post_id);
    //Se la richiesta va in errore, torno false, sarebbe opportuno mostrare un messaggio d'errore
    if (response.data.status === false) return false;
    //Aggiorno lo stato del cuoricino nel post a seconda di quello che mi restituisce la richiesta
    //"like_status" è un nuovo parametro in output alla richeista che espone:
    //true -> se il like è stato aggiunto al post
    //false -> se il like è stato tolto dal post
    context.commit(
      'posts/updateSinglePostField',
      {
        post_id,
        field_name: 'current_user_has_liked',
        field_value: response.data.like_status,
      },
      { root: true }
    );

    const post = context.rootGetters['posts/getById'](post_id); //Ritrovo il post in questione nello store
    if (post) {
      context.commit(
        'posts/updateSinglePostField',
        {
          post_id,
          field_name: 'likes_count',
          field_value:
            response.data.like_status === true
              ? post.likes_count + 1
              : post.likes_count - 1, //Se like_status è true, aggiungo 1 al conteggio dei likes e viceversa
        },
        { root: true }
      );
    }
    return response.data;
  },

  //Bookmark di un post
  async bookmarkPost(context, post_id) {
    //Avvio la richiesta
    const response = await axios.get('/user/bookmarks/post/' + post_id);
    //Se la richiesta va in errore, torno false, sarebbe opportuno mostrare un messaggio d'errore
    if (response.data.status === false) return false;
    //Aggiorno lo stato del cuoricino nel post a seconda di quello che mi restituisce la richiesta
    //"bookmark_status" è un nuovo parametro in output alla richeista che espone:
    //true -> se il bookmark è stato aggiunto al post
    //false -> se il bookmark è stato tolto dal post
    context.commit(
      'posts/updateSinglePostField',
      {
        post_id,
        field_name: 'current_user_has_bookmarked',
        field_value: response.data.bookmark_status,
      },
      { root: true }
    );

    return response.data;
  },

  async postComment(context, type, post_id, content) {
    //Avvio la richiesta
    const response = await axios.post('/comments/' + type + '/' + post_id, {
      content,
    });
    //Se la richiesta va in errore, torno false, sarebbe opportuno mostrare un messaggio d'errore
    if (response.data.status === false) return false;
  },

  //Toggle di un follow
  async toggleFollow(context, user_id) {
    const result = await axios.get('/user/relateTo/' + user_id + '/follows');
    context.commit('updateSingleCount', {
      action: result.data.added ? 'increase' : 'decrease',
      count: 'following_count',
    });
    return result.data.added;
  },
  //Toggle delle notifiche
  async toggleGetNotified(context, user_id) {
    const result = await axios.get(
      '/user/relateTo/' + user_id + '/getNotified'
    );
    return result.data.added;
  },

  //Funzione per scaricare gli utenti suggeriti
  async fetchSuggestions(context) {
    const response = await axios.get('/generic/suggestUsers');
    context.commit('updateSuggestion', {
      users: response.data.users,
    });
    return response.data.users;
  },

  //Funzione per scaricare gli utenti suggeriti
  async updateNotificationSettings(context, { elenco }) {
    const response = await axios.post('/user/notificationSettings', {
      settings: elenco,
    });
    console.log(elenco);
    context.commit('updateNotificationSettings', {
      settings: elenco,
    });
    return response.data.status;
  },

  /**
   * Toggle di un blocco
   * 1 - L'utente viene bloccato e vengono disabilitati i post
   * 2 - L'utente perde il follow
   */
  async toggleBlock(context, user_id) {
    const result = await axios.get('/user/relateTo/' + user_id + '/blocks');

    //Aggiorno l'utente corrente con i dati ricevuti
    context.commit(
      'generic/updateToggleBlockUser',
      {
        id: user_id,
        current_user_blocked: result.data.is_blocked,
        current_user_follows: false,
      },
      { root: true }
    );

    if (result.data.is_blocked) {
      context.commit('pushBlockedUserId', user_id);
      context.commit('removeFollowedUserId', user_id);
    } else {
      context.commit('removeBlockedUserId', user_id);
    }

    //Cambiare numerazione following
    context.commit('updateSingleCount', {
      action: result.data.is_blocked ? 'decrease' : 'increase',
      count: 'following_count',
    });

    return result.data.is_blocked;
  },

  async archiviaToken(context, { token, platform, uuid }) {
    const result = await axios.post('/user/token', { token, platform, uuid });
    return result.data;
  },

  async deleteToken(context, { uuid, platform }) {
    const result = await axios.post('/user/deleteDeviceToken', {
      uuid,
      platform,
    });
    return result.data;
  },

  //0 disabilita le notifiche, 1 le abilita.
  async toggleNotifications(context, { enabled }) {
    const result = await axios.post('/user/toggleNotifications', { enabled });
    //Cambiare numerazione following
    if (result.data.status) {
      context.commit('updateNotificationsDisabled', {
        settings: enabled == 0 ? 1 : 0,
      });
    }

    return result.data;
  },
};
