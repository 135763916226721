import { findIndex } from 'lodash-es'

export default {
  storeGroup(state, { group_object }) {
    const groupIndex = findIndex(
      state.groups,
      (o) => o.identity === group_object.identity,
    )
    if (groupIndex !== -1) {
      state.groups[groupIndex] = group_object
    } else {
      state.groups.push(group_object)
    }
  },

  updateGroupField(state, {id, field_name, field_value}){
    const groupIndex = findIndex(
        state.groups,
        (o) => parseInt(o.identity) === parseInt(id),
    );

    console.log('groupIndex', groupIndex);
    console.log('groupIndex', {id, field_name, field_value});
    if (groupIndex !== -1) {
      state.groups[groupIndex].properties[field_name] = field_value;
      console.log('state.groups[groupIndex]', state.groups[groupIndex]);
    }
  },

  deleteGroup(state, group_id) {
    const groupIndex = findIndex(state.groups, (o) => o.identity === group_id)

    if (groupIndex !== -1) {
      state.groups.splice(groupIndex, 1)
    }
  },

  storeUser(state, { type, user }) {
    if (!state.group_users[type]) state.group_users[type] = [];
    const userIndex = findIndex(
      state.group_users[type],
      (o) => o.user.id === user.user.id,
    )
    if (userIndex !== -1) {
      state.group_users[type][userIndex] = user
    } else {
      state.group_users[type].push(user)
    }
  },

  resetUser(state, { type }) {
    state.group_users[type] = []
  },

  updateUserConfirmed: (state, {group_id, user_id} ) => {
    const groupUserIndex = findIndex(
      state.group_users['unconfirmed'],
      (o) => o.user.id === user_id,
    );

    console.log('state.group_users', state.group_users);
    console.log('{group_id, user_id}', {group_id, user_id});
    console.log('groupUserIndex', groupUserIndex);

    if (groupUserIndex !== -1) {
      //rimuovo in unconfirmed e lo inserisco in confirmed
      let temp =  state.group_users['unconfirmed'][groupUserIndex]
      state.group_users['unconfirmed'].splice(groupUserIndex, 1)
      temp.subscription_status = {
        is_admin: 0,
        is_mod: 0,
        is_banned: 0,
        is_confirmed: 1,
      }

      const groupIndex = findIndex(state.groups, (o) => o.identity === group_id);
      console.log('groupIndex', groupIndex);
      if ( groupIndex !== -1 ) {
        if ( ! state.groups[groupIndex].properties.users_count_confirmed ) state.groups[groupIndex].properties.users_count_confirmed = 0;
        if ( ! state.groups[groupIndex].properties.users_count_unconfirmed ) state.groups[groupIndex].properties.users_count_unconfirmed = 1;
        state.groups[groupIndex].properties.users_count_confirmed = state.groups[groupIndex].properties.users_count_confirmed + 1;
        state.groups[groupIndex].properties.users_count_unconfirmed = state.groups[groupIndex].properties.users_count_unconfirmed - 1;
      }

      //controllo se l'utente esiste in confirmed, giusto per stare tranquillo
      const newGroupIndex = findIndex(
        state.group_users['confirmed'],
        (o) => o.user.id === user_id,
      )
      if (newGroupIndex !== -1) {
        state.group_users['confirmed'][newGroupIndex] = temp
      } else {
        state.group_users['confirmed'].push(temp)
      }
    }
  },

  updateUserBanned: (state, {group_id, user_id}) => {
    const groupUserIndex = findIndex(
      state.group_users['confirmed'],
      (o) => o.user.id === user_id,
      );
    if (groupUserIndex !== -1) {
      //rimuovo in user_unconfirmed e lo inserisco in user_banned
      let temp = state.group_users['confirmed'][groupUserIndex]
      state.group_users['confirmed'].splice(groupUserIndex, 1)
      temp.subscription_status.is_banned = 1;


      const groupIndex = findIndex(state.groups, (o) => o.identity === group_id);
      console.log('groupIndex', groupIndex);
      console.log('state.groups[groupIndex]', state.groups[groupIndex]);
      if ( groupIndex !== -1 ) {
        if ( ! state.groups[groupIndex].properties.users_count_banned ) state.groups[groupIndex].properties.users_count_banned = 0;
        if ( ! state.groups[groupIndex].properties.users_count_confirmed ) state.groups[groupIndex].properties.users_count_confirmed = 1;
        state.groups[groupIndex].properties.users_count_banned = state.groups[groupIndex].properties.users_count_banned + 1;
        state.groups[groupIndex].properties.users_count_confirmed = state.groups[groupIndex].properties.users_count_confirmed - 1;
      }

      //controllo se l'utente esiste in user_banned, giusto per stare tranquillo
      if (!state.group_users['banned']) state.group_users['banned'] = [];
      const newGroupIndex = findIndex(
        state.group_users['banned'],
        (o) => o.user.id === user_id,
      )
      if (newGroupIndex !== -1) {
        state.group_users['banned'][newGroupIndex] = temp
      } else {
        state.group_users['banned'].push(temp)
      }
    }
  },

  updateUserUnBanned: (state, {group_id, user_id}) => {
    const groupUserIndex = findIndex(
      state.group_users['banned'],
      (o) => o.user.id === user_id,
    )
    if (groupUserIndex !== -1) {
      //rimuovo in user_banned e lo inserisco in user_confirmed
      const temp = state.group_users['banned'][groupUserIndex]
      state.group_users['banned'].splice(groupUserIndex, 1)
      temp.subscription_status.is_banned = 0;


      const groupIndex = findIndex(state.groups, (o) => o.identity === group_id);
      if ( groupIndex !== -1 ) {
        if ( ! state.groups[groupIndex].properties.users_count_banned ) state.groups[groupIndex].properties.users_count_banned = 1;
        if ( ! state.groups[groupIndex].properties.users_count_confirmed ) state.groups[groupIndex].properties.users_count_confirmed = 0;
        state.groups[groupIndex].properties.users_count_banned = state.groups[groupIndex].properties.users_count_banned - 1;
        state.groups[groupIndex].properties.users_count_confirmed = state.groups[groupIndex].properties.users_count_confirmed + 1;
      }

      //controllo se l'utente esiste in user_confirmed, giusto per stare tranquillo
      if (!state.group_users['confirmed']) state.group_users['confirmed'] = [];
      const newGroupIndex = findIndex(
        state.group_users['confirmed'],
        (o) => o.user.id === user_id,
      )
      if (newGroupIndex !== -1) {
        state.group_users['confirmed'][newGroupIndex] = temp
      } else {
        state.group_users['confirmed'].push(temp)
      }
    }
  },

  updateUserPermission: (state, { id, type }) => {
    const groupIndex = findIndex(
      state.group_users['confirmed'],
      (o) => o.user.id === id,
    )
    if (groupIndex !== -1) {
      state.group_users['confirmed'][groupIndex].subscription_status = {
        is_admin: type === 'admin' ? 1 : 0,
        is_mod: type === 'mod' ? 1 : 0,
        is_banned: 0,
        is_confirmed: 1,
      }
    }
  },

  updateSubscribeUser: (state, { item }) => {
    const groupIndex = findIndex(state.groups, (o) => o.identity === item.id)
    if (groupIndex !== -1) {
      state.groups[groupIndex].properties.current_user_subscription = {
        is_admin: 0,
        is_mod: 0,
        is_banned: 0,
        is_confirmed: item.privacy === 'public' ? 1 : 0,
      }
    } else {
      //Non esiste nello store, la trasferisco
      const group_object = {
        identity: item.id,
        labels: ['GroupNode'],
        properties: {
          bp_id: item.bp_id,
          created_at: item.created_at,
          current_user_subscription: {
            is_admin: 0,
            is_mod: 0,
            is_banned: 0,
            is_confirmed: item.privacy === 'public' ? 1 : 0,
          },
          description: item.description,
          name: item.name,
          privacy: item.privacy,
          users_count: item.users_count,
        },
      }
      const groupIndex = findIndex(
        state.groups,
        (o) => o.identity === group_object.identity,
      )
      if (groupIndex !== -1) {
        state.groups[groupIndex] = group_object
      } else {
        state.groups.push(group_object)
      }
    }
  },

  updateUnsubscribeUser: (state, id) => {
    const groupIndex = findIndex(state.groups, (o) => o.identity === id)
    if (groupIndex !== -1) {
      state.groups[groupIndex].properties.current_user_subscription = null
    }
  },
}
