import {findIndex} from 'lodash-es';

export default {
    //state
    set_selected_user_id(state, value){
        state.selected_user_id = value
    },
    setList(state, value){
        for(let i in value){
            const single_line = value[i];
            const found_user_id = findIndex(state.chat_list, (o) => o.user_id === single_line.user_id );
            if ( found_user_id >= 0 ){
                state.chat_list[found_user_id] = single_line;
            } else {
                state.chat_list.push(single_line);
            }
        }
    },
    deleteFromList(state, user_id){
        const found_in_list = findIndex(state.chat_list, (o) => o.user_id === parseInt( user_id ));
        if ( found_in_list < 0 ) return false;
        state.chat_list.splice(found_in_list, 1);
    },
    pushMedias(state, value){
        for(let i in value){
            const index = findIndex(state.medias, (o) => o.identity === value[i].identity);
            if ( index < 0 ) {
                state.medias.push(value[i]);
            }
        }
    },
    pushUsers(state, value){
        for(let i in value){
            const index = findIndex(state.users, (o) => o.id === value[i].id);
            if ( index < 0 ) {
                state.users.push(value[i]);
            }
        }
    },
    setUserOnlineStatus(state, {user_id, status}){
        const index = findIndex(state.users, (o) => o.id === parseInt(user_id));
        if ( index < 0 ) return false;

        state.users[index].chat_status = status === true ? 1 : 0;
    },
    setUserWriting(state, {user_id, status}){
        const index = findIndex(state.users, (o) => o.id === parseInt(user_id));
        if ( index < 0 ) return false;

        state.users[index].is_writing = status;
    },
    updateListMessageField(state, {user_id, field_name, field_value}) {
        const index = findIndex(state.chat_list, (o) => o.user_id === parseInt(user_id));
        if (index < 0) return false;

        state.chat_list[index][field_name] = field_value;
    },
    updateListMessage(state, {user_id, message, media_id, created_at, unread}){
        console.log('updateListMessage', {user_id, message, media_id, created_at, unread});
        const index = findIndex(state.chat_list, (o) => o.user_id === user_id);
        if ( index < 0 ){
            let obj = {
                user_id,
                last_message: message,
                last_media_ids: media_id,
                created_at,
            };

            if ( unread !== undefined ) obj.unread = unread;
            state.chat_list.push(obj)
        } else {
            state.chat_list[index].last_message = message;
            state.chat_list[index].last_media_ids = !media_id || media_id.length <= 0 ? null : media_id;
            state.chat_list[index].created_at = created_at;
            if ( unread !== undefined ) {
                state.chat_list[index].unread = unread;
            }
        }
    }
}
