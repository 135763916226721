export default {
  storeCurrentProfile(state, { profile }) {
    if (state.currentProfile === null) {
      state.currentProfile = {}
    }
    state.currentProfile[profile.id] = profile
    console.log(state.currentProfile)
  },
  clearCurrentProfile(state, { id }) {
    if (state.currentProfile === null) return
    state.currentProfile[id] = null
  },
  updateToggleBlockUser(
    state,
    { id, current_user_blocked, current_user_follows },
  ) {
    if ( ! state.currentProfile || ! state.currentProfile[id] ) return false;
    state.currentProfile[id].current_user_blocked = current_user_blocked
    state.currentProfile[id].current_user_follows = current_user_follows
  },
}
