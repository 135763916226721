import { findIndex} from 'lodash-es';

export default {
    //state, getters
    items: (state) => {
        return JSON.parse(JSON.stringify(state.items)).map((item) => {
            item.id = `item_${item.comment.identity}`;
            item.identity = item.comment.identity;
            item.replys = item.replys.map((item) => {
                item.id = `item_reply_${item.comment.identity}`;
                item.identity = item.comment.identity;
                return item;
            });
            return item;
        })
    },
    likes: (state) => state.likes,
    getParentCommentIndex: (state) => (id) => {
        const parentFinded = findIndex(state.items, (item) => {
            const replyIndex = findIndex(item.replys, (o) => o.id === id);
            return replyIndex >= 0;
        });

        if ( parentFinded >= 0 ){
            return {
                index: parentFinded,
                is_parent: true
            };
        }

        const commentFinded = findIndex(state.items, (item) => item.id === id);
        return {
            index: commentFinded,
            is_parent: false
        };
    }
}
