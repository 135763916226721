import { find, filter, findIndex } from 'lodash-es';
export default {
    //state, getters
    selected_user_id: (state) => state.selected_user_id,
    select_user_by_id: (state) => (id) => find(state.users, (o) => o.id === id),
    select_medias_by_ids: (state) => (ids) => filter(state.medias, (o) => ids.indexOf(o.identity) >= 0),
    chat_list: (state, getters) => state.chat_list
        .sort( (a, b) => new Date(b.created_at) - new Date(a.created_at) )
        .map((o) => {
            o.user = getters.select_user_by_id(o.user_id);
            o.medias = ! o.last_media_ids ? null : getters.select_medias_by_ids(o.last_media_ids);
            return o;
        }),
    hasUnreadMessages: (state) => findIndex(state.chat_list, (o) => o.unread === 1) >= 0
}
