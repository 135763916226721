import axios from '../../../boot/axios'

export default {
  //context
  async fetch(context, post_id) {
    const result = await axios.get('/comments/' + post_id)
    context.commit('setAll', result.data.comments)
  },
  async fetchLikes(context, post_id) {
    const result = await axios.get('/likes/' + post_id)
    context.commit('setAllLikes', result.data.users)
  },
  async store(context, { post, id, content, replysTo, replysToId }) {
    const response = await axios.post(
      '/comments/' + replysTo + (replysToId ? '/' + replysToId : ''),
      {
        id,
        content,
      },
    )

    if (response.data.status === true) {
      if (!id) {
        context.commit('addItem', {
          item: response.data.comment,
          parent_id: replysTo !== 'post' ? replysToId : null,
        })

        //Aggiornare conteggio singolo post solo se è nuovo non editato
        context.commit(
          'posts/updateSinglePostField',
          {
            post_id: post.id,
            field_name: 'comments_count',
            field_value: post.comments_count + 1,
          },
          { root: true },
        )
      } else {
        context.commit('updateItem', response.data.comment)
      }
    }
  },
  async delete(context, {post, id}) {
    const response = await axios.delete('/comments/' + id)
    if (response.data.status === true) {
      context.commit('deleteItem', id)
      //Aggiornare conteggio singolo post solo se è nuovo non editato
      context.commit(
        'posts/updateSinglePostField',
        {
          post_id: post.id,
          field_name: 'comments_count',
          field_value: post.comments_count - 1,
        },
        { root: true },
      )
      return true
    }

    return false
  },
  async toggleLike(context, id) {
    const response = await axios.get('/user/likes/comment/' + id)
    const like_added = response.data.added
    return context.commit('toggleLike', { id, like_added })
  },
}
