<template>
  <ion-content>
    <div class="home-container maintenance">

      <div class="logo-container ion-margin-bottom">
        <ion-img src="/assets/logo/white_motto.png" style="width: 300px;" />
      </div>

      <ion-card class="home-card">
        <h1 class="main-title">
          {{ $t('components.ServerMaintenance.title') }}
        </h1>
        <div class="main-animation-wrapper">
          <div>
            <lottie-animation :animation-data="UnderConstructionAnimation" :loop="true" :auto-play="true" :speed="1" />
          </div>
        </div>

        <h5 class="subtitle">
          <strong>{{ $t('components.ServerMaintenance.subtitle') }}</strong>
          <br />
          <br />
          {{ $t('components.ServerMaintenance.description') }}
        </h5>
      </ion-card>
    </div>
  </ion-content>
</template>

<script>
import UnderConstructionAnimation from '../../resources/animations/under-construction.json'
export default {
  name: "ServerMaintenance",
  setup: () => ({
    UnderConstructionAnimation
  })
};
</script>

<style lang="scss" scoped>
.home-container {
  &.maintenance {
    background: url('../../public/assets/images/backgrounds/pattern-512-150.png') !important;

    .main-animation-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      >div {
        height: 80%;

        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }

    .main-title {
      color: black;
      text-align: center;
    }

    .subtitle {
      margin: 0;
      color: #000;
      text-align: center;

      padding: 10px;
    }

    .home-card {
      @media screen and (max-width: 768px) {
        width: 95vw !important;
      }
    }
  }
}
</style>
