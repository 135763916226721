import { parseSettings } from '../../../helpers/notificationHelper';

export default {
  //state, getters
  user: (state) => state.user,
  user_has_active_app: (state) => state.user_has_active_app,
  claims: (state) => state.claims,
  counts: (state) => state.counts,
  node: (state) => state.node,
  forgotPasswordData: (state) => state.forgotPasswordData,
  forgotPasswordEmail: (state) => state.forgotPasswordData.email,
  forgotPasswordToken: (state) => state.forgotPasswordData.token,
  suggestions: (state) => state.suggestions,
  notificationSettings: (state) =>
    state.node.notification_settings !== undefined
      ? parseSettings(JSON.parse(state.node.notification_settings))
      : parseSettings([]),
  followedUsersIds: (state) => state.followedUsersIds,
  emailVerified: (state) => state.emailVerified,
  verificationEmailSentAt: (state) => state.verificationEmailSentAt,
  isUserBlocked: (state) => (user_id) =>
    state.blockedUsersIds
      ? state.blockedUsersIds.indexOf(parseInt(user_id)) >= 0
      : false,
  isUserFollowed: (state) => (user_id) =>
    state.followedUsersIds
      ? state.followedUsersIds.indexOf(parseInt(user_id)) >= 0
      : false,
  chatUnread: (state) => state.chatUnread,
  isFirstLogin: (state) => state.isFirstLogin,
  internalNotificationsData: (state) => state.internalNotificationsData,
  notificationNativeEnabled: (state) => state.notificationNativeEnabled,
};
