import actions from './actions'
import mutations from './mutations';
import getters from './getters';

const state = () => ({
  groups: [], //gruppi dell'utente 
  user_confirmed: [], //elenco utenti confermati gruppo corrente
  user_banned: [], //elenco utenti bannati gruppo corrente
  user_unconfirmed: [], //elenco richieste gruppo corrente
  group_users: {}
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
