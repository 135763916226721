import { filter, find, map } from 'lodash-es';
export default {
  groups: (state) =>
    map(state.groups, (node) => ({
      id: node.identity,
      ...node.properties,
    })),
  getById: (state, getters) => (id) => {
    return find(getters.groups, (o) => o.id === parseInt(id));
  },
  getGroupsByAdminOrMod: (state, getters) => {
    return filter(
      getters.groups,
      (o) =>
        o.current_user_subscription !== null &&
        (o.current_user_subscription.is_admin === 1 ||
          o.current_user_subscription.is_mod === 1)
    );
  },
  getUserConfirmedById: (state, getters) => (id) => {
    return filter(getters.groupUsers('confirmed'), (o) => o.user.id === id)[0];
  },
  groupUsers: (state) => (type) => {
    return state.group_users[type] !== undefined ? state.group_users[type] : [];
  },
};
