import getters from './getters'
import actions from './actions'
import mutations from './mutations'


const state = () => ({
  medias: [],
  deletedMedia: [],
  post_model: {
    id: null,
    title: null,
    privacy_type: 'all',
    group_id: null,
    is_ad: false,
    ad_link: null,
    ad_promotional_text: null,
    ad_call_to_action: null,
    ad_labels: []
  },
  selectedMedia: null
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
