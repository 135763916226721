import axios from '../../../boot/axios'

export default {
  //Funzione di get notifiche
  async fetchNotifications(context, { page }) {
    const result = await axios.get('/notifications', { params: { page } })
    console.log('fetchNotifications', result)
    for (let i in result.data.notifications) {
      context.commit('storeNotification', {
        item: result.data.notifications[i],
      })
    }
    return result.data.status
  },

  //Funzione set readed notification
  async readNotification(context, id) {
    const result = await axios.post('/notifications', { id })
    console.log('readNotification', result)
    context.commit('readedNotifications', {
      id: id,
    })
    if (id !== undefined) {
      context.commit(
        'user/updateSingleCount',
        { action: 'decrease', count: 'unreaded_notifications_count' },
        { root: true },
      )
    } else {
      context.commit(
        'user/updateSingleCount',
        { action: 'all', count: 'unreaded_notifications_count' },
        { root: true },
      )
    }
    return result.data.status
  },

  //Funzione set unreaded notification
  async unReadNotification(context, id) {
    const result = await axios.post('/notifications/unread', { id })
    console.log('unReadNotification', result)
    context.commit('unReadedNotifications', {
      id: id,
    })
    context.commit(
      'user/updateSingleCount',
      { action: 'increase', count: 'unreaded_notifications_count' },
      { root: true },
    )

    return result.data.status
  },

  //Funzione di cancellazione notifica
  async deleteNotification(context, id) {
    const result = await axios.delete('/notifications/' + id )
    context.commit('deletedNotifications', {
      id: id,
    })
    return result.data.status
  },

  //Funzione set readed notification
  async newPushNotification(context, { notification }) {
    console.log(notification);
    context.commit('storePushNotification', {
      item: notification,
    })
    context.commit(
      'user/updateSingleCount',
      { action: 'increase', count: 'unreaded_notifications_count' },
      { root: true },
    )
    return true
  },
}
