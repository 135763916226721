/* eslint-disable no-console */

import axios from '../../../boot/axios';

import {v4 as uuidv4} from 'uuid';
import {toastController} from "@ionic/vue";

const getDataURL = (file) => {
  return new Promise( (resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(file);
  } );
};

const getImageDimensions = (file) => {
  return new Promise (function (resolve) {
    let i = new Image()
    i.onload = function(){
      resolve({width: i.width, height: i.height})
    };
    i.src = file
  })
}

export default {
  //context
  //Getter dei media
  async fetchMedia(context, page){
    const response = await axios.get('/user/media', {params: {page}});
    console.log('response.data.medias.length', response.data.medias.length);
    if ( response.data.medias.length > 0 ){
      for(let i in response.data.medias){
        //console.log('response.data.medias[i]', response.data.medias[i]);
        context.commit('setMedia', response.data.medias[i]);
      }
    }
  },

  //Eliminazione di un media
  async deleteMedia(context, ids){
    for(let i in ids){
      context.commit('deleteSelected', ids[i]);
      context.commit('deleteMedia', ids[i]);
    }
    /*const response = await axios.delete('/user/media', {params: {id: ids}});
    if ( response.data.status === true ){
      for(let i in ids){
        context.commit('deleteSelected', ids[i]);
        context.commit('deleteMedia', ids[i]);
      }
    }*/
  },

  //Salvataggio di un media
  async saveMedia(context, {media, onUploadProgress, tmpProgress}){
    let formData;
    let params = {};
    const media_type = media.media_type === undefined ? media.type : media.media_type;
    if ( media_type === 'text' ){
      formData = {
        content: media.textContent,
        title: media.title,
        id: media._id
      }
    } else {
      formData = new FormData();
      if ( media.file ) {
        formData.append('file', media.file);
      }
      params['title'] = media.title;
      params['id'] = media._id
    }

    let endpoint = '/user/media/' + media_type;
    if ( media_type !== 'text' && media._id ){
      endpoint = '/user/update/media/' + media_type;
    }

    let response;
    if ( ! tmpProgress ) {

      response = await axios.post(endpoint, formData, {
        headers: {
          'Content-type': (media_type === 'text' ? 'application/json' : 'multipart/form-data; boundary=' + formData.boundary)
        },
        onUploadProgress,
        params
      });

      if ( response.data.status === false ) return false;

      return response.data.media;

    } else {
      const abortController = new AbortController();
      const media_uuid = uuidv4();

      const mediaFileData = await getDataURL(media.file);
      let tmp_media_obj = {
        _id: media_uuid,
        type: media_type,
        path: mediaFileData,
        is_data_url: true,
        progress: 'indeterminate',
        _labels: ["MediaNode"],
        abortController
      };

      if ( tmp_media_obj.type === 'image' ){
        const imageSize = getImageDimensions(mediaFileData);
        tmp_media_obj.image_width = imageSize.width;
        tmp_media_obj.image_height = imageSize.height;
      }

      context.commit('postsEditor/addMedia', tmp_media_obj, {root: true})

      axios.post(endpoint, formData, {
          signal: abortController.signal,
          headers: {
            'Content-type': (media_type === 'text' ? 'application/json' : 'multipart/form-data; boundary=' + formData.boundary)
          },
          onUploadProgress: (progressEvent) => {
            context.commit('postsEditor/setTmpMediaProgress', {
              media_uuid,
              progress: ((100 / progressEvent.total) * progressEvent.loaded) / 100
            }, {root: true})
          },
          params
        })
        .then((response) => {
          if ( response.data.status === true ) {
            context.commit('postsEditor/updateMedia', {
              media_uuid,
              media_object: response.data.media
            }, {root: true});
          } else {
            context.commit('postsEditor/deleteMediaByUuid', media_uuid, {root: true});
            toastController.create({
              message: 'Errore nel caricamento del file ' + media.file.name,
              duration: 3000,
              color: 'danger',
              buttons: [
                {
                  text: 'OK',
                  side: 'end',
                  role: 'cancel',
                },
              ],
            }).then( (toast) => toast.present() )
          }
        });

      return tmp_media_obj;

    }
  }
};
