/* eslint-disable no-unused-vars */
import axios from '../../../boot/axios';

const rewriteNode = (node) => ({
  id: node.identity,
  ...node.properties,
});

export default {
  /**
   * Creazione Aggiornamento di un gruppo
   * @param {*} context
   * @param {*} obj { id, privacy_type, name, description, type }
   * @returns
   */
  async createUpdateGroup(context, { id, privacy_type, name, description }) {
    const result = await axios.post('/groups', {
      id,
      privacy_type,
      name,
      description,
    });
    context.commit('storeGroup', {
      group_object: result.data.group,
    });
    return { status: result.data.status, id: result.data.group.identity };
  },

  /**
   * Download Esplora Gruppi
   * @param {*} context
   * @param {*} obj { page }
   * @returns
   */
  async fetchPublicGroups(context, { page }) {
    const result = await axios.get('/groups', { params: { page } });
    if (result.data.groups.length <= 0) return [];
    for (let i in result.data.groups) {
      result.data.groups[i] = {
        ...rewriteNode(result.data.groups[i]),
      };
    }
    return result.data.groups;
  },

  /**
   * Download Gruppi a cui sono iscritto
   * @param {*} context
   * @param {*} obj { user_id, page }
   * @returns
   */
  async fetchUserAddedGroups(context, { user_id, page }) {
    const result = await axios.get('/user/groups/' + user_id, {
      params: { page },
    });
    console.log('fetchUserAddedGroups', result);
    if (result.data.groups.length <= 0) return [];
    for (let i in result.data.groups) {
      context.commit('storeGroup', {
        group_object: result.data.groups[i],
      });
    }
    return result.data.groups.length;
  },

  /**
   * Download Gruppi creati da me
   * @param {*} context
   * @param {*} obj { page }
   * @returns
   */
  async fetchUserAdminGroups(context, { page }) {
    const result = await axios.get('/user/admin/groups', { params: { page } });
    console.log('fetchUserAdminGroups', result);
    if (result.data.groups.length <= 0) return [];
    for (let i in result.data.groups) {
      context.commit('storeGroup', { group_object: result.data.groups[i] });
    }
    return result.data.status;
  },

  /**
   * Download Singolo Gruppo
   * @param {*} context
   * @param {*} id id del gruppo
   * @returns
   */
  async fetchSingleGroup(context, id) {
    const result = await axios.get('/group/' + id);
    console.log('fetchSingleGroup', result);
    if (!result.data.status) {
      return { status: result.data.status, error: result.data.error };
    } else {
      context.commit('storeGroup', {
        group_object: result.data.group,
      });
      return {
        status: result.data.status,
        error: result.data.error,
      };
    }
  },

  /**
   * Download Utenti in base al filtro
   * @param {*} context
   * @param {*} obj {group_id, filter, page}
   * @returns
   */
  async fetchUsersGroup(context, { group_id, filter, page }) {
    const result = await axios.get('/group/' + group_id + '/users', {
      params: { filter, page },
    });

    if (page === 1) {
      context.commit('resetUser', { type: filter });
    }
    for (let i in result.data.users) {
      context.commit('storeUser', {
        type: filter,
        user: result.data.users[i],
      });
    }
    return result.data.status;
  },

  /**
   * Accettazione Utente nel gruppo
   * @param {*} context
   * @param {*} obj {group_id, user_id}
   * @returns
   */
  async setUserConfirmGroup(context, { group_id, user_id }) {
    const result = await axios.post(
      '/group/' + group_id + '/setUserConfirm/' + user_id
    );
    console.log('setUserConfirmGroup', result);
    context.commit('updateUserConfirmed', { group_id, user_id });
    return result.data.status;
  },

  /**
   * Bannare utente dal gruppo o dal regno :P
   * @param {*} context
   * @param {*} obj {group_id, user_id}
   * @returns
   */
  async setBannedUserGroup(context, { group_id, user_id }) {
    const result = await axios.get('/group/ban/' + group_id + '/' + user_id);
    console.log('setBannedUserGroup', result);
    context.commit('updateUserBanned', { group_id, user_id });
    return result.data.status;
  },

  /**
   * Rimuovere Ban utente dal gruppo o dal regno :P
   * @param {*} context
   * @param {*} obj {group_id, user_id}
   * @returns
   */
  async setUnBannedUserGroup(context, { group_id, user_id }) {
    const result = await axios.get(
      '/group/ban/' + group_id + '/' + user_id + '?unban=1'
    );
    console.log('setBannedUserGroup', result);
    context.commit('updateUserUnBanned', { group_id, user_id });
    return result.data.status;
  },

  //'admin' | 'mod' | 'user'
  /**
   * Cambio Permessi utente
   * @param {*} context
   * @param {*} obj { group_id, user_id, permission }
   * @returns
   */
  async changePermissionUserGroup(context, { group_id, user_id, permission }) {
    const result = await axios.get(
      '/group/perms/' + group_id + '/' + user_id + '/' + permission
    );
    console.log('changePermissionUserGroup', result);
    context.commit('updateUserPermission', { id: user_id, type: permission });
    return result.data.status;
  },

  /**
   * Cancellazione di un gruppo
   * @param {*} context
   * @param {*} id
   * @returns
   */
  async deleteAdminGroup(context, { id }) {
    const result = await axios.delete('/group/' + id);
    console.log('deleteGroup', result);
    context.commit('deleteGroup', id);
    return result.data.status;
  },

  /**
   * Iscrizione Utente corrente al gruppo
   * @param {*} context
   * @param {*} group_id
   * @param {*} privacy public | private
   * @returns
   */
  async subscribeUser(context, { item, id }) {
    id = id ? id : item.id;
    const result = await axios.get('/group/subscribe/' + id);
    console.log('subscribeUser', result, item);
    if (result.status) {
      item.current_user_subscription = {
        is_admin: 0,
        is_mod: 0,
        is_banned: 0,
        is_confirmed: 1,
      };
    }
    context.commit('updateSubscribeUser', { item });
    return result.data.status;
  },

  /**
   * Esci dal gruppo
   * @param {*} context
   * @param {*} obj { group_id }
   * @returns
   */
  async unsubscribeUser(context, { group_id }) {
    const result = await axios.get('/group/unsubscribe/' + group_id);
    console.log('unsubscribeUser', result.data.status);
    context.commit('updateUnsubscribeUser', group_id);
    return result.data.status;
  },

  /*
    Aggiornamento dell'immagine di profilo o di copertina
    imageType può essere avatar | backdrop
    peso massimo consentito: 25mb
    estensioni consentite: jpg, png, bmp, gif

    La funzione aggiorna automaticamente lo store e torna boolean
   */
  async updateImage(context, { group_id, imageType, image }) {
    const formData = new FormData();
    formData.append('image', image);
    const response = await axios.post(
      '/group/' + group_id + 'updateImage/' + imageType,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    console.log('updateImage', response);
    if (response.data.status === false) return false;

    return true;
  },
};
