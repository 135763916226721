import axios from '../../../boot/axios';
import { filter, shuffle, sortBy } from 'lodash-es';
export default {
  //context
  async fetch(context, { page, type, type_id, itemsPerPage }) {
    console.log('fetch', { page, type, type_id, itemsPerPage });
    const result = await axios.get(
      '/posts/' + type + (type_id !== undefined ? '/' + type_id : ''),
      { params: { page, itemsPerPage } }
    );
    console.log('fetch', result);

    if (result.data.result && result.data.result.length <= 0) return false;

    //const randomInRange = (min, max) => Math.floor(Math.random() * (max - min) + min);

    const get_current_items = () =>
      context.getters.items[type] !== undefined &&
      context.getters.items[type][type_id] !== undefined
        ? context.getters.items[type][type_id]
        : context.getters.items[type] !== undefined
        ? context.getters.items[type]
        : [];

    let current_items,
      ad_posts = [];
    if (type === 'following') {
      let ad_posts_all = JSON.parse(
        JSON.stringify(shuffle(context.getters.ad_posts))
      );
      current_items = JSON.parse(JSON.stringify(get_current_items()));
      ad_posts = ad_posts_all.map((o) => {
        o.published_times = filter(current_items, (c) => o.id === c.id).length;
        return o;
      });
      ad_posts = filter(
        ad_posts,
        (o) => o.published_times < o.ad_campaign_repeat_in_a_day
      );
      ad_posts = sortBy(ad_posts, (o) => o.published_times);
    }

    console.log('ad_posts', ad_posts);
    for (let i in result.data.result) {
      if (type === 'following' && ad_posts.length > 0) {
        for (let i in ad_posts) {
          if (
            current_items.length > 0 &&
            current_items[current_items.length - 1] !== undefined &&
            current_items[current_items.length - 1].is_ad !== 1 &&
            current_items[current_items.length - 2] !== undefined &&
            current_items[current_items.length - 2].is_ad !== 1 &&
            current_items[current_items.length - 3] !== undefined &&
            current_items[current_items.length - 3].is_ad !== 1 &&
            current_items[current_items.length - 4] !== undefined &&
            current_items[current_items.length - 4].is_ad !== 1 &&
            current_items[current_items.length - 5] !== undefined &&
            current_items[current_items.length - 5].is_ad !== 1 &&
            current_items[current_items.length - 6] !== undefined &&
            current_items[current_items.length - 6].is_ad !== 1 &&
            current_items[current_items.length - 7] !== undefined &&
            current_items[current_items.length - 7].is_ad !== 1 &&
            current_items[current_items.length - 8] !== undefined &&
            current_items[current_items.length - 8].is_ad !== 1 &&
            current_items[current_items.length - 9] !== undefined &&
            current_items[current_items.length - 9].is_ad !== 1 &&
            current_items[current_items.length - 10] !== undefined &&
            current_items[current_items.length - 10].is_ad !== 1
          ) {
            console.log('prev_post', current_items[current_items.length - 1]);
            let ad_post = ad_posts[i];
            const already_published_ad_post_count = filter(
              current_items,
              (o) => o.id === ad_post.id
            ).length;
            const published_ad_posts = filter(
              current_items,
              (o) => o.is_ad === 1
            );
            const last_published_ad_post_id =
              published_ad_posts && published_ad_posts.length > 0
                ? published_ad_posts[published_ad_posts.length - 1].id
                : null;
            if (
              already_published_ad_post_count <
                ad_post.ad_campaign_repeat_in_a_day &&
              (!last_published_ad_post_id ||
                last_published_ad_post_id !== ad_post.id)
            ) {
              ad_posts[i].published_times =
                (ad_posts[i].published_times === undefined
                  ? 0
                  : ad_posts[i].published_times) + 1;
              context.commit('addPostDirect', { post_object: ad_post });
              current_items = JSON.parse(JSON.stringify(get_current_items()));

              ad_posts = filter(
                ad_posts,
                (o) => o.published_times < o.ad_campaign_repeat_in_a_day
              );
              ad_posts = sortBy(ad_posts, (o) => o.published_times);
            }
          } else {
            current_items = JSON.parse(JSON.stringify(get_current_items()));
          }
        }
      }

      context.commit('addPost', { ...result.data.result[i], type, type_id });
    }

    /*if ( type === 'following' ) {
      const ad_post = JSON.parse(JSON.stringify(context.getters.ad_posts))[page - 1];
      let position = randomInRange((page - 1) * (itemsPerPage / 2), (itemsPerPage / 2));
      const actual_items = context.getters.items.following;
      while(
          ( (actual_items[position - 1] && actual_items[position - 1].is_ad === 1) || ((actual_items[position + 1] && actual_items[position + 1].is_ad === 1)) )
          ){
        position = randomInRange((page - 1) * (itemsPerPage / 2), (itemsPerPage / 2));
      }
      context.commit('addPostAtPosition', {position, post_object: ad_post})
    }*/

    context.commit('setLatestPage', { page, type, type_id });

    return true;
  },

  async getAds(context) {
    const response = await axios.get('/posts/ad');
    context.commit('resetAdPosts');
    for (let i in response.data.result) {
      context.commit('addAdPost', response.data.result[i]);
    }
  },

  async store(context, data) {
    const result = await axios.post('/posts', data);
    if (result.data.status === true && result.data.post) {
      /*
      posso salvare sullo store:
      - muro
      - sul mio profilo se la pagina è il mio profilo
      - su un gruppo se il post coincide su un gruppo.
      */
      if (
        context.getters.currentWall.type !== 'user' ||
        (context.getters.currentWall.type === 'user' &&
          context.rootGetters['user/node']._id ===
            context.getters.currentWall.type_id) ||
        (context.getters.currentWall.type === 'group' &&
          parseInt(context.getters.currentWall.id) ===
            parseInt(data.group_id) &&
          data.privacy_type === 'group')
      ) {
        var to_add;

        switch (context.getters.currentWall.type) {
          case 'user':
            //aggiungo allo store del profilo
            to_add = {
              ...context.getters.currentWall,
              prepend: true,
              ...result.data.post,
            };

            context.commit('addPost', to_add);

            //aggiungo allo store del muro
            to_add = {
              type: 'following',
              type_id: null,
              prepend: true,
              ...result.data.post,
            };

            context.commit('addPost', to_add);
            break;
          case 'following':
            //aggiungo allo store del muro
            to_add = {
              ...context.getters.currentWall,
              prepend: true,
              ...result.data.post,
            };

            context.commit('addPost', to_add);

            //aggiungo allo store del profilo
            to_add = {
              type: 'user',
              type_id: context.rootGetters['user/node']._id,
              prepend: true,
              ...result.data.post,
            };

            context.commit('addPost', to_add);
            break;
          default:
            to_add = {
              ...context.getters.currentWall,
              prepend: true,
              ...result.data.post,
            };

            context.commit('addPost', to_add);
            break;
        }
      }
    }
    return result.data;
  },

  async delete(context, { id, user_id }) {
    const result = await axios.delete('/posts/' + id);

    if (result.data.status === true) {
      context.commit('delete', { id, user_id });
      context.commit(
        'user/updateSingleCount',
        {
          action: 'decrease',
          count: 'posts_count',
        },
        { root: true }
      );
    }
  },

  /*create(context, post_model){},*/
};
