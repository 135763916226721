import { findIndex, filter } from 'lodash-es'

export default {
  //state
  setMedia(state, mediaObject) {
    const media = filter(state.media, (o) => o !== null && o !== undefined)
    const mediaIndex = findIndex(media, (o) => o._id === mediaObject._id)
    if (mediaIndex >= 0) {
      state.media[mediaIndex] = mediaObject
    } else {
      state.media.push(mediaObject)
    }
  },
  addStaticMedia(state, mediaObject){
    const mediaIndex = findIndex(state.media, (o) => o._id === mediaObject._id);
    if ( mediaIndex < 0 ){
      state.media.push(mediaObject);
    }
  },
  deleteMedia(state, id) {
    const mediaIndex = findIndex(state.media, (o) => o._id === id)
    state.media.splice(mediaIndex, 1)
  },
  toggleSelected(state, id) {
    const findedSelectedMedia = state.selectedIds.indexOf(id)
    if (findedSelectedMedia >= 0) {
      state.selectedIds.splice(state.selectedIds.indexOf(id), 1)
    } else {
      state.selectedIds.push(id)
    }
  },
  addSelected(state, id) {
    const findedSelectedMedia = state.selectedIds.indexOf(id)
    if (findedSelectedMedia < 0) {
      state.selectedIds.push(id)
    }
  },
  deleteSelected(state, id) {
    const findedSelectedMedia = state.selectedIds.indexOf(id);
    console.log('deleteSelected', findedSelectedMedia)
    if (findedSelectedMedia >= 0) {
      state.selectedIds.splice(state.selectedIds.indexOf(id), 1)
    }
  },
  resetSelected(state) {
    state.selectedIds = []
  },
}
