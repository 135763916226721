import { find, keys } from 'lodash-es';

export default {
  //state, getters
  currentWall: (state) => state.currentWall,
  items: (state) => state.items,
  items_grouped: (state) => {
    let to_return = {};

    keys(state.items).forEach((i) => {
      const items = state.items[i];

      if (items.length === 0) {
        to_return[i] = [];
        return to_return;
      }

      to_return[i] = [items[0]];
      for (let k = 1; k < items.length; k++) {
        const item = items[k];
        const lastItem = to_return[i][to_return[i].length - 1];
        if (
          (Array.isArray(lastItem) && lastItem[0].user.id === item.user.id) ||
          (!Array.isArray(lastItem) && item.user.id === lastItem.user.id)
        ) {
          if (!Array.isArray(lastItem)) {
            to_return[i][to_return[i].length - 1] = [lastItem, item];
          } else {
            lastItem.push(item);
          }
        } else {
          to_return[i].push(item);
        }
      }
    });

    return to_return;
  },
  ad_posts: (state) => {
    const posts = state.ad_posts;
    let to_return = [];
    for (let i in posts) {
      const post = posts[i];
      const ad_campaign_repeat_in_a_day = post.ad_campaign_repeat_in_a_day;

      if (ad_campaign_repeat_in_a_day && ad_campaign_repeat_in_a_day > 0) {
        let z = 1;
        while (z <= ad_campaign_repeat_in_a_day) {
          to_return.push(JSON.parse(JSON.stringify(post)));
          z = z + 1;
        }
      }
    }

    return to_return
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  },
  getById: (state) => (id) => {
    const items = state.currentWall.type_id
      ? state.items[state.currentWall.type][state.currentWall.type_id]
      : state.items[state.currentWall.type];
    return find(items, (o) => o !== undefined && o.id === id);
  },
  getLatestPage: (state) => (type, type_id) => {
    if (!state.latestPage[type]) return null;
    if (type_id && !state.latestPage[type][type_id]) return null;

    if (type_id) {
      return state.latestPage[type][type_id];
    } else {
      return state.latestPage[type];
    }
  },
  new_posts: (state) => state.new_posts,
};
