<template>
  <template v-if="isMobileAndroid">
    <div class="container" id="banner_container">
      <div class="banner">
        <button @click="close" class="close_button">
          <ion-icon slot="icon-only" :icon="closeSharp"></ion-icon>
        </button>
        <ion-img src="assets/images/ic_launcher.png" class="img_app" />
        <div class="data_banner">
          <span style="font-weight:bold; font-size: 12px;">{{ $t('components.Popups.InstallApp.appName') }}</span>
          <span style="color: grey;font-size: 11px;">{{ $t('components.Popups.InstallApp.company') }}</span>
          <span style="color: grey;font-size: 11px;">{{ isMobileAndroid ? $t('components.Popups.InstallApp.platformAndroid') : $t('components.Popups.InstallApp.platformIOS') }}</span>
        </div>
        <div class="data_banner">
          <ion-button size="small" fill="clear" @click="openLink">{{ data }}</ion-button>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { closeSharp } from "ionicons/icons";
import appInterface from '../../mixins/appInterface';
import { isPlatform } from '@ionic/core';

export default {
  name: "InstallApp",
  mixins: [appInterface],
  setup: () => ({
    closeSharp
  }),
  data: () => ({
    data: 'Installa',
    isOpen: true
  }),
  methods: {
    openLink() {
      if (isPlatform('android')) {
        if (this.data === this.$t('components.Popups.InstallApp.install')) {
          window.open('https://play.google.com/store/apps/details?id=com.dantebus')
        } else {
          window.open('com.dantebus://');
        }
      }
      if (isPlatform('ios')) {
        if (this.data === this.$t('components.Popups.InstallApp.install')) {
          window.open('https://apps.apple.com/it/app/dantebus/id1265411620')
        } else {
          window.open('com.dantebus.app://');
        }
      }
    },
    close() {
      document.getElementById('banner_container').style.display = 'none';
    },
  },
  async mounted() {
    if (isPlatform('android')) {
      const response = await this.checkIfAppAndroidInstalled();
      this.data = response === true ? this.$t('components.Popups.InstallApp.open') : this.$t('components.Popups.InstallApp.install');
      if (response === true) {
        try {
          window.location = 'com.dantebus://' + window.location.pathname;
        } catch (error) {
          console.log('Non posso aprire il link')
        }
      }
    } else {
      this.data = this.$t('components.Popups.InstallApp.open');
      setTimeout(() => { this.data = this.$t('components.Popups.InstallApp.install') }, 25);
    }
  }
}
</script>

<style lang="scss" scoped>
.img-logo-ios {
  width: 60px;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 5px;
}

.banner {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.container {
  position: absolute;
  top: 0;
  background: white;
  left: 0;
  right: 0;
  color: black;
}

.img_app {
  margin: 10px 5px 10px 0px;
  height: 50px;
  width: 50px;
  border-radius: 10px !important;
  overflow: hidden;
}

.close_icon {
  height: 30px;
  width: 30px;
  color: gray;
}

.data_banner {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.close_button {
  width: 30px;
  height: 25px;
  color: #000;
  padding: 0;
  border: none;
  background: none;
  outline: none;
}
</style>
