import { createStore } from 'vuex'
import user from './modules/user';
import userMedia from './modules/userMedia';
import posts from './modules/posts'
import groups from './modules/groups'
import generic from './modules/generic';
import userNotification from './modules/userNotification';
import postComments from './modules/postComments';
import postsEditor from './modules/postsEditor'
import chat from './modules/chat'

export default createStore({
  modules: {
    user,
    userMedia,
    posts,
    groups,
    generic,
    userNotification,
    postComments,
    postsEditor,
    chat
  },
})
