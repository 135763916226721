import { filter, find, indexOf } from 'lodash-es'

export default {
  //state, getters
  media: (state) => filter(state.media, (o) => o).sort((a, b) => {
    return new Date(a.created_at) - new Date(b.created_at)
  }).reverse(),
  selectedIds: (state) => state.selectedIds,
  getById: (state, getters) => (id) => find(getters.media, (o) => o._id === id),
  getMultipleById: (state, getters) => (ids) => filter(getters.media, (o) => indexOf(ids, o._id) >= 0).sort( (a,b) => ids.indexOf(a._id) - ids.indexOf(b._id) )
}
