import { find, keys, forEach } from 'lodash-es'

export const settings = {
  comment: {
    value: false,
    name: 'Commenti',
  },
  reply: {
    value: false,
    name: 'Risposte',
  },
  comment_tag: {
    value: false,
    name: 'Tag o Mensioni',
  },
  subscription_request: {
    value: false,
    name: 'Richieste di iscrizione a gruppi',
  },
  new_post: {
    value: false,
    name: 'Nuovo post pubblicato da un utente che segui',
  },
  new_subscription: {
    value: false,
    name: 'Nuovi Iscritti ai gruppi',
  },
  new_follower: {
    value: false,
    name: 'Nuovi Followers',
  },
  like: {
    value: false,
    name: 'Mi Piace',
  },
  group_request_accepted: {
    value: false,
    name: 'Richiesta di adesione al gruppo accettata',
  },
  group_new_permission_admin: {
    value: false,
    name: 'Sei diventato Admin di un gruppo',
  },
  group_new_permission_mod: {
    value: false,
    name: 'Sei diventato Moderatore di un gruppo',
  },
  group_unban: {
    value: false,
    name: 'Sei stato bannato da un gruppo',
  },
  group_ban: {
    value: false,
    name: 'Non sei più bannato da un gruppo',
  },
}

export const parseSettings = (elenco) => {
  forEach(keys(settings), (item) => {
    const value = find(elenco, (o2) => o2 === item)
    if (value === undefined) {
      settings[item].value = true
    }else{
      settings[item].value = false
    }
  })
  return settings
}
