import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = () => ({
  user: null,
  user_has_active_app: false,
  claims: null,
  node: null,
  profile: null,
  counts: null,
  forgotPasswordData: { token: null, email: null },
  suggestions: null,
  followedUsersIds: [],
  blockedUsersIds: [],
  chatUnread: false,
  emailVerified: null,
  verificationEmailSentAt: null,
  internalNotificationsData: null,
  notificationNativeEnabled: false,
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
