import axios from "../../../boot/axios";

export default {
    //context
    async fetchMedias(context, {ids, sortByIds, replace}){
        const response = await axios.get('/user/media/byIds', {
            params: {
                ids,
            },
        });

        if ( replace ){
            context.commit('setMedias', [])
        }

        if ( response.data.status === true ){
            const response_medias = response.data.medias.reverse();
            for (let i in response_medias) {
                context.commit('addMedia', response_medias[i]);
            }
        }

        if (sortByIds) {
            context.commit('sortMediaByIds', ids);
        }
    }
}
