import {filter, map} from 'lodash-es';

const rewriteNode = (node) => {
  let toReturn = {
    id: node.identity,
    name: node.labels[0],
    ...node.properties,
  }
  if(node.relationship){
    toReturn.relationship = rewriteNode(node.relationship);
  }
  return toReturn;
}

export default {
  notifications: (state) => {
    if (!state.notifications) return [];
    let notifications = JSON.parse( JSON.stringify(state.notifications) )

    notifications.sort((a,b) => {
      console.log('a', a);
      console.log('b', b);
      return new Date(b.notification.properties.created_at) - new Date(a.notification.properties.created_at);
    });

    //Filtro per notifiche bloccate
    filter(notifications, (o) => o.from_user !== null)
    return map(notifications, (item) => {
      return {
        id: item.notification.identity,
        from_user: rewriteNode(item.from_user),
        notification: rewriteNode(item.notification),
        subject: item.subject !== null ? rewriteNode(item.subject) : rewriteNode(item.from_user),
        to_user: rewriteNode(item.to_user)
      }
    })
  },
}