import getters from './getters'
import actions from './actions'
import mutations from './mutations'


const state = () => ({
  selected_user_id: null,
  chat_list: [],
  medias: [],
  users: [],
  chat_messages: [],

})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
