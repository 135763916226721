import { format } from "date-fns"
import { map, findIndex } from 'lodash-es'

export default {
  storeNotification(state, {item}){
    const notificationIndex = findIndex(
      state.notifications,
      (o) => o.notification.identity === item.notification.identity,
    )
    if (notificationIndex != -1) {
      state.notifications[notificationIndex] = item
    } else {
      state.notifications.push(item)
    }
  },

  storePushNotification(state, {item}){
    const notificationIndex = findIndex(
      state.notifications,
      (o) => o.notification.identity === item.notification.identity,
    )
    if (notificationIndex != -1) {
      state.notifications[notificationIndex] = item
    } else {
      state.notifications.unshift(item)
    }
  },

  readedNotifications(state, {id}){
    if(id !== undefined){
      const notificationIndex = findIndex(
        state.notifications,
        (o) => o.notification.identity === id,
      )
      if (notificationIndex != -1) {
        state.notifications[notificationIndex].notification.properties.readed_at = format(new Date(), 'yyyy-mm-dd HH:mm:ss')
      }
    }else{
      state.notifications = map(state.notifications, (item) => {
        item.notification.properties.readed_at = new Date();
        return item
      })
    } 
  },

  unReadedNotifications(state, {id}){
    console.log(id);
    if(id !== undefined){
      const notificationIndex = findIndex(
        state.notifications,
        (o) => o.notification.identity === id,
      )
      if (notificationIndex != -1) {
        state.notifications[notificationIndex].notification.properties.readed_at = undefined
      }
    }
  },

  deletedNotifications(state, {id}){
    console.log(id);
    if(id !== undefined){
      const notificationIndex = findIndex(
        state.notifications,
        (o) => o.notification.identity === id,
      )
      if (notificationIndex != -1) {
        state.notifications.splice(notificationIndex, 1);
      }
    }
  },

  resetNotifications(state){
    state.notifications = [];
  }
}