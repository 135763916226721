import { findIndex } from 'lodash-es';

const rewriteNode = (node) => ({
  id: node.identity,
  ...node.properties,
});

export default {
  //state
  resetPosts(state, { type, type_id }) {
    if (!state.items[type]) state.items[type] = type_id === undefined ? [] : {};
    if (type_id && !state.items[type][type_id]) state.items[type][type_id] = [];
  },
  resetAdPosts(state) {
    state.ad_posts = [];
  },
  addAdPost(
    state,
    {
      post,
      user,
      group,
      media,
      likes_count,
      comments_count,
      current_user_has_liked,
      current_user_has_bookmarked,
    }
  ) {
    let medias = null;
    if (media) {
      medias = [];
      for (let i in media) {
        medias.push(rewriteNode(media[i]));
      }
    }

    //console.log('comments_count', comments_count);

    const post_object = {
      ...rewriteNode(post),
      user: {
        ...rewriteNode(user),
        current_user_follows: user.current_user_follows,
      },
      group,
      medias: medias,
      likes_count,
      comments_count,
      current_user_has_liked,
      current_user_has_bookmarked,
    };

    state.ad_posts.push(post_object);
  },
  addPostAtPosition(state, { position, post_object }) {
    console.log('post_object', post_object);
    if (Array.isArray(state.items.following)) {
      state.items.following.splice(position, 0, post_object);
    }
  },
  addPostDirect(state, { post_object }) {
    if (Array.isArray(state.items.following)) {
      state.items.following.push(post_object);
    }
  },
  addPost(
    state,
    {
      type,
      type_id,
      post,
      user,
      group,
      media,
      likes_count,
      comments_count,
      current_user_has_liked,
      current_user_has_bookmarked,
      prepend,
    }
  ) {
    let medias = null;
    if (media) {
      medias = [];
      for (let i in media) {
        medias.push(rewriteNode(media[i]));
      }
    }

    //console.log('comments_count', comments_count);

    const post_object = {
      ...rewriteNode(post),
      user: {
        ...rewriteNode(user),
        current_user_follows: user.current_user_follows,
      },
      group,
      medias: medias,
      likes_count,
      comments_count,
      current_user_has_liked,
      current_user_has_bookmarked,
    };

    if (!state.items[type]) state.items[type] = type_id === undefined ? [] : {};
    if (type_id && !state.items[type][type_id]) state.items[type][type_id] = [];

    console.log('state.items', state.items);

    const postIndex = findIndex(
      type_id ? state.items[type][type_id] : state.items[type],
      (o) => o !== undefined && o.id === post.identity
    );
    if (postIndex < 0) {
      if (!prepend) {
        if (type_id) {
          if (Array.isArray(state.items[type][type_id])) {
            state.items[type][type_id].push(post_object);
          }
        } else {
          if (Array.isArray(state.items[type])) {
            state.items[type].push(post_object);
          }
        }
      } else {
        if (type_id) {
          if (Array.isArray(state.items[type][type_id])) {
            state.items[type][type_id].unshift(post_object);
          }
        } else {
          if (Array.isArray(state.items[type])) {
            state.items[type].unshift(post_object);
          }
        }
      }
    } else {
      if (type_id) {
        state.items[type][type_id][postIndex] = post_object;
      } else {
        state.items[type][postIndex] = post_object;
      }
    }
  },

  delete(state, { id, user_id }) {
    console.log('state.currentWall', state, { id, user_id });
    var posts;
    var postIndex;

    switch (state.currentWall.type) {
      case 'following':
        posts = state.items[state.currentWall.type];
        console.log('posts_wall', posts);
        postIndex = findIndex(posts, (o) => o !== undefined && o.id === id);
        if (postIndex >= 0) {
          state.items[state.currentWall.type].splice(postIndex, 1);
        }

        //rimuovo dal profilo
        posts = state.items['user'][user_id];
        console.log('posts_utente', posts);
        postIndex = findIndex(posts, (o) => o !== undefined && o.id === id);
        if (postIndex >= 0) {
          state.items['user'][user_id].splice(postIndex, 1);
        }
        break;
      case 'user':
        posts = state.currentWall.type_id
          ? state.items[state.currentWall.type][state.currentWall.type_id]
          : state.items[state.currentWall.type];
        console.log('posts', posts);
        postIndex = findIndex(posts, (o) => o !== undefined && o.id === id);
        if (postIndex >= 0) {
          state.items[state.currentWall.type][state.currentWall.type_id].splice(
            postIndex,
            1
          );
        }

        //rimuovo dal muro
        posts = state.items['following'];
        console.log('posts', posts);
        postIndex = findIndex(posts, (o) => o !== undefined && o.id === id);
        if (postIndex >= 0) {
          state.items['following'].splice(postIndex, 1);
        }

        break;
      default:
        //console.log('state.items', state.items);
        posts = state.currentWall.type_id
          ? state.items[state.currentWall.type][state.currentWall.type_id]
          : state.items[state.currentWall.type];
        console.log('posts', posts);
        postIndex = findIndex(posts, (o) => o !== undefined && o.id === id);
        if (postIndex >= 0) {
          if (state.currentWall.type_id) {
            state.items[state.currentWall.type][
              state.currentWall.type_id
            ].splice(postIndex, 1);
          } else {
            state.items[state.currentWall.type].splice(postIndex, 1);
          }
        }
        break;
    }
  },

  setLatestPage(state, { page, type, type_id }) {
    console.log('state.latestPage', state.latestPage, { page, type, type_id });
    if (!state.latestPage[type])
      state.latestPage[type] = type_id === undefined ? null : {};
    if (type_id && !state.latestPage[type][type_id])
      state.latestPage[type][type_id] = null;

    if (type_id) {
      state.latestPage[type][type_id] = page;
    } else {
      state.latestPage[type] = page;
    }
  },

  updateSinglePostField(
    state,
    { post_id, field_name, field_value, field_increase }
  ) {
    const posts = state.currentWall.type_id
      ? state.items[state.currentWall.type][state.currentWall.type_id]
      : state.items[state.currentWall.type];
    const post_index = findIndex(
      posts,
      (o) => o !== undefined && o.id === post_id
    );
    if (post_index < 0) return false;

    if (!field_increase) {
      if (state.currentWall.type_id) {
        state.items[state.currentWall.type][state.currentWall.type_id][
          post_index
        ][field_name] = field_value;
      } else {
        state.items[state.currentWall.type][post_index][field_name] =
          field_value;
      }
    } else {
      let base_value;
      if (state.currentWall.type_id) {
        base_value =
          state.items[state.currentWall.type][state.currentWall.type_id][
            post_index
          ][field_name];
      } else {
        base_value =
          state.items[state.currentWall.type][post_index][field_name];
      }

      if (state.currentWall.type_id) {
        state.items[state.currentWall.type][state.currentWall.type_id][
          post_index
        ][field_name] = !base_value
          ? field_value
          : parseFloat(base_value) + parseFloat(field_value);
      } else {
        state.items[state.currentWall.type][post_index][field_name] =
          !base_value
            ? field_value
            : parseFloat(base_value) + parseFloat(field_value);
      }
    }
  },

  setCurrentPostsWall(
    state,
    { type, type_id } = {
      type: 'following',
      type_id: undefined,
    }
  ) {
    state.currentWall.type = type;
    state.currentWall.type_id = !type_id ? null : type_id;
  },

  setNewPosts(state, value) {
    state.new_posts = value;
  },
};
