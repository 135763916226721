import {findIndex} from "lodash-es";

export default {
    //state
    setDeletedMedia(state, value){
        state.deletedMedia = value;
    },
    addDeletedMedia(state, value){
        state.deletedMedia.push(value);
    },
    setMedias(state, value){
        state.medias = value;
    },
    addMedia(state, media){
        state.medias.push(media);
    },
    deleteMediaByUuid(state, uuid){
        const findedMedia = findIndex(state.medias, (o) => o._id === uuid);
        if ( findedMedia >= 0 ) {
            state.medias.splice(findedMedia, 1);
        }
    },
    setMediaTextContent(state, {id, textContent}){
        const index = findIndex(state.medias, (o) => o._id === id);
        if ( index >= 0 ){
            state.medias[index].textContent = textContent;
        }
    },
    sortMediaByIds(state, ids){
        state.medias = state.medias.sort(
            (mediaA, mediaB) =>
                ids.indexOf(mediaA._id) - ids.indexOf(mediaB._id)
        );
    },
    setPostModel(state, value){
        state.post_model = value;
    },
    setPostModelField(state, {field, value}){
        state.post_model[field] = value;
    },
    resetState(state){
        state.medias = [];
        state.deletedMedia = [];
        state.selectedMedia = null;
        state.post_model = {
            id: null,
            title: null,
            privacy_type: 'all'
        }
    },
    setTmpMediaProgress(state, {media_uuid, progress}){
        const findedMedia = findIndex(state.medias, (o) => o._id === media_uuid);
        if ( findedMedia >= 0 ){
            state.medias[findedMedia].progress = progress;
        }
    },
    updateMedia(state, {media_uuid, media_object}){
        const findedMedia = findIndex(state.medias, (o) => o._id === media_uuid);
        if ( findedMedia >= 0 ){
            state.medias[findedMedia] = media_object;
            console.log('state.selectedMedia', state.selectedMedia);
            console.log('media_uuid', media_uuid);
            if ( state.selectedMedia === media_uuid ){
                state.selectedMedia = media_object._id
            }
        }
    },
    setSelectedMedia(state, value){
        state.selectedMedia = value;
    }
}
