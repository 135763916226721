import actions from './actions'
import mutations from './mutations';
import getters from './getters';

const state = () => ({
  currentProfile: null
})


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
