export default {
  methods: {
    getNotificationContent(fromUserName, action, subject) {
      const name =
          subject !== null ? this.getNameNode(action, subject) : undefined;
      switch (action) {
        case 'new_follower':
          return this.$t('mixins.notificationInterface.newFollower', { fromUserName });
        case 'subscription_request':
          return this.$t('mixins.notificationInterface.subscriptionRequest', { fromUserName, subjectName: subject.name });
        case 'comment':
          return this.$t('mixins.notificationInterface.comment', { fromUserName, name: name !== undefined ? name : '' });
        case 'like':
          return this.$t('mixins.notificationInterface.like', { fromUserName, name: name !== undefined ? name : '' });
        case 'new_post':
          return this.$t('mixins.notificationInterface.newPost', { fromUserName });
        case 'comment_tag':
          return this.$t('mixins.notificationInterface.commentTag', { fromUserName, name: name !== undefined ? name : '' });
        case 'new_subscription':
          return this.$t('mixins.notificationInterface.newSubscription', { fromUserName, subjectName: subject.name });
        case 'reply':
          return this.$t('mixins.notificationInterface.reply', { fromUserName, name: name !== undefined ? name : '' });
        case 'group_request_accepted':
          return this.$t('mixins.notificationInterface.groupRequestAccepted', { subjectName: subject.name });
        case 'group_new_permission_admin':
          return this.$t('mixins.notificationInterface.groupNewPermissionAdmin', { subjectName: subject.name });
        case 'group_new_permission_mod':
          return this.$t('mixins.notificationInterface.groupNewPermissionMod', { subjectName: subject.name });
        case 'group_unban':
          return this.$t('mixins.notificationInterface.groupUnban', { subjectName: subject.name });
        case 'group_ban':
          return this.$t('mixins.notificationInterface.groupBan', { subjectName: subject.name });
        default:
          break;
      }
    },
    getNameNode(action, subject) {
      var node = subject.relationship ? subject.relationship.name : subject.name;
      switch (action) {
        case 'like':
        case 'reply':
        case 'group_request_accepted':
        case 'group_new_permission_admin':
        case 'group_new_permission_mod':
        case 'group_unban':
        case 'group_ban':
          node = subject.name;
          break;
        case 'comment':
          node = subject.relationship.name;
          break;
        default:
          break;
      }

      switch (node) {
        case 'PostNode':
          return this.$t('mixins.notificationInterface.post');
        case 'GroupNode':
          return this.$t('mixins.notificationInterface.group');
        case 'CommentNode':
          return this.$t('mixins.notificationInterface.comment1');
        default:
          break;
      }
    },
    getRouter(action, item) {
      const rottaChat = '/chat/' + item.from_user.id;
      const rottaPost = '/post/' + item.subject.id;
      const rottaGruppi = '/group/' + item.subject.id;
      const rottaGruppiRequests = '/group/' + item.subject.id + '/members/unconfirmed';
      const rottaProfilo = '/profile/' + item.from_user.id;
      const rottaCommenti =
          item.subject.name === 'CommentNode'
              ? '/post/' +
              item.subject.relationship.id +
              '/' +
              item.subject.id +
              '?t=' +
              new Date().getTime()
              : rottaPost;

      let classes = {
        subscription_request: rottaGruppiRequests,
        comment: rottaCommenti,
        like: rottaCommenti,
        new_post: rottaPost,
        comment_tag: rottaCommenti,
        new_subscription: rottaGruppi,
        group_request_accepted: rottaGruppi,
        group_new_permission_admin: rottaGruppi,
        group_new_permission_mod: rottaGruppi,
        group_unban: rottaGruppi,
        group_ban: rottaGruppi,
        new_follower: rottaProfilo,
        reply: rottaCommenti,
        chat_message: rottaChat,
      };
      if (action in classes) return classes[action];
    },
  },
};
