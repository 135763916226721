export default {
  components: {
    Chat: {
      FollowingAutocomplete: {
        label_destinatario: 'Destinatario',
        message_no_user_found:
          'Nessun utente trovato. Ricorda: puoi chattare solo con le persone che segui',
      },
      NewMessage: {
        titles: {
          newMessage: 'Nuovo Messaggio',
          write: 'Scrivi...',
        },
        buttons: {
          cancel: 'Annulla',
          send: 'Invia',
          attachFiles: 'Allega files',
        },
        labels: {
          writeMessage: 'Scrivi un messaggio',
        },
        toast: {
          sendMessageError: 'Impossibile inviare il messaggio: {error}',
          sendMessageUnexpectedError:
            'Impossibile inviare il messaggio: Errore inaspettato. Riprova più tardi.',
        },
      },
    },
    Comment: {
      Mention: {
        MentionList: {
          no_results: 'Nessun Risultato',
        },
      },
      CommentEditor: {
        title_new_message: 'Nuovo Messaggio',
        title_write: 'Scrivi...',
        button_cancel: 'Annulla',
        button_send: 'Invia',
        button_save: 'Salva',
        button_attach_files: 'Allega files',
        placeholder_write_message: 'Scrivi un messaggio',
        placeholder_write_comment: 'Scrivi un commento…',
        placeholder_write_reply: 'Scrivi una risposta…',
        toast_send_error: 'Impossibile inviare il messaggio: ',
        toast_send_unexpected_error:
          'Impossibile inviare il messaggio: Errore inaspettato. Riprova più tardi.',
        emojiPickerLanguage: {
          search: 'Cerca...',
          categories: {
            search: 'Risultati della ricerca',
            recent: 'Recenti',
            smileys: 'Smileys e Emozioni',
            people: 'Persone e Corpo',
            nature: 'Animali e Natura',
            foods: 'Cibo e Bevande',
            activity: 'Attività',
            places: 'Viaggi e Luoghi',
            objects: 'Oggetti',
            symbols: 'Simboli',
            flags: 'Bandiere',
            custom: 'Personalizzate',
          },
        },
      },
      CommentItem: {
        no_results: 'Nessun Risultato',
        like: 'Mi Piace',
        unlike: 'Non mi piace',
        button_edit: 'Modifica',
        button_cancel_edit: 'Annulla modifica',
        button_delete: 'Elimina',
        button_report: 'Segnala',
        button_reply: 'Rispondi',
        button_cancel_reply: 'Annulla risposta',
        alert_delete_header: 'Elimina',
        alert_delete_message: 'Vuoi davvero eliminare questo commento?',
        alert_delete_cancel: 'Annulla',
        alert_delete_confirm: 'Elimina',
      },
      ModalComment: {
        followButton: 'Segui',
        segment: {
          comments: 'Commenti',
          likes: 'Mi Piace',
        },
      },
    },
    Groups: {
      GroupCreate: {
        title_new_group: 'Nuovo Gruppo',
        label_title: 'Titolo',
        label_description_optional: 'Decrizione (Facoltativa)',
        label_privacy: 'Privacy',
        option_public_group: 'Gruppo Pubblico',
        option_private_group: 'Gruppo Privato',
        button_delete: 'Elimina',
        button_cancel: 'Annulla',
        button_update: 'Aggiorna',
        button_publish: 'Pubblica',
        delete_confirmation_header: 'Elimina Gruppo',
        delete_confirmation_message:
          'Stai per eliminare questo gruppo: {name}. Vuoi davvero procedere?',
      },
      GroupImageUpload: {
        update: 'Aggiorna',
        avatar: 'avatar',
        cover: 'copertina',
        dragHere: "Trascina qui un'immagine",
        selectImage: "Seleziona un'immagine",
        save: 'Salva',
        cancel: 'Annulla',
        toast: {
          invalidImage: "Non hai selezionato un'immagine",
        },
      },
      GroupItem: {
        subscribe: 'Iscriviti',
        subscribeToGroup: 'Iscriviti al gruppo',
        requestMembership: 'Richiedi Adesione',
        requestSent: 'Richiesta Inviata',
        cancel: 'Annulla',
        subscribed: 'Iscritto',
        public: 'Pubblico',
        private: 'Privato',
        members: 'iscritti',
      },
      GroupItemAdminSettings: {
        followers: 'Seguaci',
        following: 'Seguiti',
        buttons: {
          banUser: 'Banna Utente',
        },
        permissions: 'Permessi',
        roles: {
          admin: 'Amministratore',
          moderator: 'Moderatore',
          user: 'Utente',
        },
      },
      GroupList: {
        noGroups: {
          part1: 'Non sei iscritto a nessun gruppo. Clicca su',
          explore: 'ESPLORA',
          part2: 'per trovare nuovi gruppi.',
        },
        loadingText: 'Caricamento...',
      },
      GroupsHome: {
        myGroups: {
          part1: 'I miei',
          part2: 'Gruppi',
        },
        manage: 'Gestisci',
        explore: 'Esplora',
        subscribedGroups: 'Gruppi a cui sei iscritto',
        subscribedGroupsWithName: 'Gruppi a cui è iscritto/a {name}',
        createdAndManagedGroups: 'Gruppi creati e Amministrati da te',
        newGroup: 'Nuovo Gruppo',
        exploreDantebusGroups: 'Esplora i gruppi su Dantebus',
      },
      GroupUserItem: {
        followers: 'Follower',
        following: 'Seguiti',
        accept: 'Accetta',
        removeBan: 'Rimuovi Ban',
        administrator: 'Amministratore',
        moderator: 'Moderatore',
        subscribed: 'Iscritto',
      },
      GroupUsersList: {
        loadingText: 'Caricamento...',
      },
      ToggleSubscriptionButton: {
        leaveGroup: 'Esci dal gruppo',
        requestSent: 'Richiesta Inviata',
        cancel: 'Annulla',
        subscribe: 'Iscriviti',
        subscribed: 'Iscritto',
      },
    },
    MediaItem: {
      FullScreenImage: {
        close: 'Chiudi',
      },
    },
    Notification: {
      NotificationList: {
        noNotifications: 'Non ci sono notifiche',
        loadingText: 'Caricamento...',
      },
      NotificationPopover: {
        notifications: 'Notifiche',
        markAsRead: 'Segna come letto',
      },
    },
    PageError: {
      NotFound: {
        message:
          'Ci dispiace, non puoi visualizzare questo post. Probabilmente non hai i permessi sufficienti oppure è stato eliminato.',
      },
    },
    Popovers: {
      AddMediaItem: {
        uploadFile: 'Carica un file',
        writeText: 'Scrivi un testo',
        selectFromLibrary: 'Seleziona dalla libreria',
      },
      ChatOptions: {
        profile: 'Profilo',
        unblockUser: 'Sblocca utente',
        blockUser: 'Blocca utente',
        deleteConversation: 'Elimina conversazione',
        loading: 'Caricamento...',
      },
      ChatSingleMessageOptions: {
        deleteMessage: 'Elimina messaggio',
      },
      PostItemMenu: {
        report: 'Segnala',
        edit: 'Modifica',
        delete: 'Elimina',
        loading: 'Caricamento...',
        deletePostHeader: 'Elimina post',
        deletePostMessage:
          'Stai per eliminare questo post. Vuoi davvero procedere?',
        cancel: 'Annulla',
        post: 'Post',
      },
      RequestPermission: {
        title: 'Notifiche Push',
        message:
          "Concedi l'autorizzazione a ricevere le notifiche push sul tuo dispositivo.\nNon perderti nessuna notifica",
        deny: 'Dopo',
        grant: 'Concedi',
      },
    },
    Popups: {
      EmailChangeOTP: {
        confirmEmailAddresses: 'Conferma gli indirizzi email',
        confirmEmail: "Conferma l'indirizzo email",
        securityMessageVerified:
          "Per questioni di sicurezza <strong>abbiamo inviato due codici di conferma</strong>: uno al tuo vecchio indirizzo email e uno al nuovo, <strong>inseriscili di seguito per proseguire</strong> al cambio dell'indirizzo email.",
        securityMessage:
          'Per questioni di sicurezza <strong>abbiamo inviato un codice di conferma</strong> al tuo nuovo indirizzo email, <strong>inseriscilo di seguito per proseguire</strong>.',
        oldEmailCode: 'Codice vecchia email ({email})',
        newEmailCode: 'Codice nuova email ({new_email})',
        emailReceivedCode: 'Codice ricevuto per email ({new_email})',
        confirm: 'Conferma',
        success:
          "Indirizzo email aggiornato con successo. Effettua di nuovo l'accesso per proseguire.",
        error: 'Errore: {error}',
      },
      InstallApp: {
        appName: 'Dantebus Social',
        company: 'Dantebus Srl',
        platformAndroid: 'Gratis - Google Play',
        platformIOS: 'Gratis - Apple Store',
        install: 'Installa',
        open: 'Apri',
      },
      LabelsFinder: {
        searchInterests: 'Ricerca interessi..',
        searchInterest: 'Cerca un interesse',
        searchEnglishOnly: 'Ricerca solo termini in inglese.',
        interest: 'Interesse',
        publicReach: 'Pubblico raggiunto',
        selectInterest: 'Seleziona un interesse',
        addInterests_single: 'Aggiungi {count} interesse',
        addInterests_multiple: 'Aggiungi {count} interessi',
        confirmEmailAddresses: 'Conferma gli indirizzi email',
        confirmEmail: "Conferma l'indirizzo email",
        securityMessageVerified:
          "Per questioni di sicurezza <strong>abbiamo inviato due codici di conferma</strong>: uno al tuo vecchio indirizzo email e uno al nuovo, <strong>inseriscili di seguito per proseguire</strong> al cambio dell'indirizzo email.",
        securityMessage:
          'Per questioni di sicurezza <strong>abbiamo inviato un codice di conferma</strong> al tuo nuovo indirizzo email, <strong>inseriscilo di seguito per proseguire</strong>.',
        oldEmailCode: 'Codice vecchia email ({email})',
        newEmailCode: 'Codice nuova email ({new_email})',
        emailReceivedCode: 'Codice ricevuto per email ({new_email})',
        confirm: 'Conferma',
        success:
          "Indirizzo email aggiornato con successo. Effettua di nuovo l'accesso per proseguire.",
        error: 'Errore: {error}',
      },
      ReportNode: {
        report: 'Segnala {nodeName}',
        motivation: 'Motivo della segnalazione',
        cancel: 'Annulla',
        description: 'Descrivi il problema',
        optional: 'facoltativo',
        send: 'Invia',
        options: {
          annoys_me: 'Mi da fastidio',
          copyright_infringement: 'Viola il copyright',
          spam: 'È spam',
          inappropriate: 'Non dovrebbe essere su Dantebus',
        },
        error_select_motivation: 'Seleziona un motivo per continuare',
        success_report: 'Segnalazione inviata con successo',
        error_report: 'Si è verificato un errore inaspettato. Riprova',
      },
      SupportRequest: {
        reportProblem: 'Segnala un problema',
        subject: 'Oggetto',
        describeProblem: 'Descrivi il problema',
        optional: 'facoltativo',
        attentionMessage:
          'Attenzione! Lo staff tecnico risponderà alle richieste di supporto direttamente al tuo indirizzo email ({email}).',
        send: 'Invia',
        fillAllFields: 'Riempi tutti i campi prima di proseguire.',
        successReport: 'Segnalazione inviata con successo',
        errorReport: 'Si è verificato un errore inaspettato. Riprova',
      },
    },
    Portfolio: {
      AppArtistItem: {
        downloadApp: 'Scarica APP',
        installApp: 'Installa APP',
        openApp: 'Apri APP',
        visitSite: 'Visita il Sito',
        freeGooglePlay: 'Gratis - Google Play',
        freeAppleStore: 'Gratis - Apple Store',
        cannotOpenLink: 'Non posso aprire il link',
      },
      DantebusEdizioniForm: {
        publishWithUs: 'Pubblica con Dantebus Edizioni',
        subtitle:
          "Il tuo Libro o il tuo Catalogo d'Arte curato da professionisti",
        submitManuscript:
          "Invia il tuo Manoscritto o il tuo Catalogo d'Arte Ora!",
        received: "Abbiamo ricevuto il tuo testo o catalogo d'arte!",
        info: 'Se il comitato scientifico lo valuterà aderente alla nostra linea editoriale, riceverai una proposta di pubblicazione.',
        placeholders: {
          fullName: 'Nome e Cognome*',
          email: 'Email*',
          phone: 'Telefono*',
          title: 'Titolo',
        },
        uploadText: 'Carica il tuo testo *',
        requiredFields: '* Campi Obbligatori',
        publishWithUsButton: 'Pubblica Con noi',
        accepts: {
          part1: 'Inviando questo moduli, accetti le nostre ',
          privacyConditions: 'Condizioni sulla privacy',
        },
        toast: {
          verifyFields: 'Verificare i campi obbligatori.',
          error:
            'Si è verificato un errore. Riprova caricando un file più leggero oppure verificare i campi obbligatori',
        },
      },
      MessageNoBookEdizioni: {
        title: 'Non hai ancora pubblicato nessun libro con Dantebus',
        subtitle: 'Vuoi pubblicare il tuo libro?',
        button: 'Pubblica con dantebus edizioni',
      },
      PortfolioHome: {
        app: 'App',
        libri: 'Libri',
        videoPoetrie: 'Video Poesie',
        gallerie3D: 'Gallerie 3D',
      },
      VideoPoetryItem: {
        watch: 'Guarda',
      },
      VirtualGalleryItem: {
        watch: 'Guarda',
      },
    },
    Post: {
      PostItem: {
        viewSingular: 'Visualizzazione',
        viewPlural: 'Visualizzazioni',
        sponsored: 'Sponsorizzato',
        subscribe: 'Iscriviti',
        subscribeToGroup: 'Iscriviti al gruppo',
        requestJoin: 'Richiedi Adesione',
        unsubscribe: 'Esci dal gruppo',
        watch: 'Guarda',
        share: 'Condividi',
        shareWithFriends: 'Condividi con i tuoi amici',
        shareTitle: 'Guarda il post di {name}',
        shareText: 'Guarda il post di {name}',
        shareDialogTitle: 'Condividi con i tuoi amici',
        adCallToAction: 'Scopri di più',
      },
      PostList: {
        card: {
          scrollMessage:
            'Scorri da destra a sinistra per visualizzare gli altri post di {username}',
        },
        postListCard: {
          noMorePosts: 'Non sono presenti altri post',
        },
        infiniteScroll: {
          loadingText: 'Caricamento...',
        },
        modal: {
          header: 'Questo è un post multiplo',
          message:
            "Quando un'artista pubblica più opere di seguito, vengono raccolte in un unico post. Scorri da destra verso sinistra per visualizzarli tutti.",
          ok: 'Ok',
        },
      },
    },
    PostCreation: {
      FileGallery: {
        newWork: 'Nuova Opera',
        addWorks: 'Aggiungi Opere',
        cancel: 'Annulla',
        select: 'Seleziona',
        newText: 'Nuovo Testo',
        uploadImage: 'Carica Immagine',
        library: 'Libreria',
        permissionToast: {
          message:
            'Concedi il permesso per visualizzare tutti i file multimediali e testuali sul tuo dispositivo',
          grant: 'Concedi',
        },
        uploadPrompt: 'Clicca sui pulsanti in alto per caricare le tue opere',
        cameraDenied:
          'Non è possibile utilizzare la funzionalità perchè è stato negato il consenso. Modificare nelle impostazioni del proprio dispositivo',
        permissionDialog: {
          denied:
            "E' stato negato il permesso. Per continuare, andare in impostazioni.",
          cancel: 'Annulla',
          openSettings: 'Apri impostazioni',
        },
      },
      FilesManage: {
        title: 'Opere',
        cancel: 'Annulla',
        next: 'Avanti',
        titleLabel: 'Titolo opera',
        caption: 'Didascalia',
        uploadPrompt: 'Clicca sui pulsanti in alto per caricare le tue opere',
      },
      FilesUploadDesktop: {
        newWork: 'Nuova opera',
        newText: 'Nuovo Testo',
        select: 'Seleziona',
        selectFromComputer: 'Seleziona dal computer',
        uploadFromLibrary: 'Carica dalla libreria',
        dragFiles: 'Trascina immagini, documenti o musica qui',
        selectFiles: 'Seleziona immagini, documenti o musica qui',
        uploadError: 'Errore nel caricamento del file {fileName}',
      },
      MediaSelector: {
        title: 'La tua libreria',
        loadingText: 'Caricamento...',
        cancel: 'Annulla',
        select: 'Seleziona',
        alert: {
          header: 'Vuoi procedere?',
          message: 'Non potrai più recuperare questo file.',
          buttons: {
            no: 'No',
            yes: 'SI',
          },
        },
      },
      MediaTextEditor: {
        loading: 'Caricamento...',
      },
      MediaTextViewer: {
        noTitle: 'Senza Titolo',
      },
      PostCreate: {
        alert: {
          header: 'Vuoi procedere?',
          message: 'Tutte le modifiche effettuate verranno perse.',
          buttons: {
            no: 'No',
            yes: 'SI',
          },
        },
      },
      PostHeadings: {
        title: 'Pubblicazione',
        cancel: 'Annulla',
        publish: 'Pubblica',
        postTitle: 'Titolo post',
        privacy: 'Privacy',
        privacyAll: 'Tutti',
        privacyFollowers: 'Chi mi segue',
        privacyOnlyMe: 'Solo io',
        privacyGroup: 'In un gruppo',
        group: 'Gruppo',
        adPost: 'Post pubblicitario',
        adUrl: 'URL al sito sponsorizzato',
        adText: 'Testo della CTA',
        adCta: 'Testo nel bottone della CTA',
        addLabel: 'Aggiungi',
        selectGroup: 'Seleziona un gruppo per proseguire',
        error: 'Si è verificato un errore, riprova tra qualche minuto.',
      },
      TextContentEditor: {
        title: 'Didascalia',
        cancel: 'Annulla',
        save: 'Salva',
        placeholder: 'Descrivi il contenuto...',
      },
    },
    Search: {
      SearchResults: {
        placeholder: 'Cerca qui...',
        noResult: 'La ricerca non ha dato risultati',
        users: 'Utenti',
        groups: 'Gruppi',
        loading: 'Caricamento...',
      },
    },
    Settings: {
      NotificationSettings: {
        title: 'Impostazioni Notifiche',
        subtitle: 'Personalizza le tue notifiche',
        notifications: 'Notifiche ',
        enabled: 'Abilitate',
        disabled: 'Disabilitate',
        active: 'Attiva',
        inactive: 'Disattiva',
        saved: 'Salvato',
        save: 'Salva',
        singleNotificationTypes: {
          comment: 'Commenti',
          reply: 'Risposte',
          comment_tag: 'Tag o Mensioni',
          subscription_request: 'Richieste di iscrizione a gruppi',
          new_post: 'Nuovo post pubblicato da un utente che segui',
          new_subscription: 'Nuovi Iscritti ai gruppi',
          new_follower: 'Nuovi followers',
          lilke: 'Mi Piace',
          group_request_accepted: 'Richiesta di adesione al gruppo accettata',
          group_new_permission_admin: 'Sei diventato Admin di un gruppo',
          group_new_permission_mod: 'Sei diventato Moderatore di un gruppo',
          group_unban: 'Non sei più bandito da un gruppo',
          group_ban: 'Sei stato bandito da un gruppo',
        },
      },
    },
    User: {
      ToggleFollowButton: {
        follow: 'Segui',
        unfollow: 'Segui già',
      },
      DeleteAccountConfirm: {
        title: "Conferma l'eliminazione dell'account",
        instruction:
          "Inserisci il codice a 6 cifre contenuto nell'email che abbiamo inviato al tuo indirizzo per confermare l'eliminazione permanente del tuo account.",
        warningTitle: 'Attenzione',
        warningMessage:
          'Tutti i tuoi posts saranno eliminati definitivamente, potrai accedere di nuovo a Dantebus soltanto creando un nuovo account.',
        codePlaceholder: 'Codice a 6 cifre',
        confirmButton: 'Conferma Eliminazione',
        invalidCode: 'Codice non valido',
        error: 'Si è verificato un errore. Riprova più tardi',
      },
      FollowersFollowing: {
        followers: 'Followers',
        following: 'Utenti seguiti',
        loading: 'Caricamento...',
      },
      ImageUpload: {
        updateAvatar: 'Aggiorna avatar',
        updateCover: 'Aggiorna copertina',
        dragHere: "Trascina qui un'immagine",
        selectImage: "Seleziona un'immagine",
        cancel: 'Annulla',
        save: 'Salva',
        invalidImage: "Non hai selezionato un'immagine",
      },
      NativeImageCropper: {
        updateAvatar: 'Aggiorna avatar',
        updateCover: 'Aggiorna copertina',
        save: 'Salva',
      },
      PrintProfile: {
        title: 'Profilo',
        yes: 'Sì',
        no: 'No',
      },
      SuggestionsHorizontal: {
        title: 'Suggeriti per te...',
        followers: 'Followers',
        following: 'Seguiti',
      },
      UpdateEmail: {
        currentEmail: 'Email attuale',
        newEmail: 'Nuovo indirizzo email',
        confirmNewEmail: 'Conferma il nuovo indirizzo email',
        verify: 'Verifica',
        enterAndConfirmEmail:
          'Inserisci e conferma il nuovo indirizzo email per proseguire',
        emailMismatch: "Non hai confermato correttamente l'indirizzo email.",
        invalidEmail: "L'indirizzo email fornito non è valido.",
        sameAsCurrentEmail:
          "L'indirizzo email fornito è uguale all'attuale, impossibile procedere.",
      },
      UpdatePassword: {
        currentPassword: 'Password attuale',
        newPassword: 'Nuova password',
        confirmNewPassword: 'Conferma la nuova password',
        save: 'Salva',
        successMessage: 'Password aggiornata con successo!',
      },
      UpdateProfile: {
        title: 'Il tuo Profilo',
        updateMoreInfo: 'Aggiorna altre informazioni',
        save: 'Salva',
        successMessage: 'Profilo aggiornato con successo',
        errorMessage: 'Si è verificato un errore durante il salvataggio',
        fields: {
          name: 'Nome utente',
          phone: 'Numero di telefono',
          first_name: 'Nome',
          last_name: 'Cognome',
          birthday: 'Data di nascita',
          small_biography: 'Breve biografia',
          biography: 'Biografia completa',
          country: 'Nazione',
          city: 'Città',
          is_artist: 'Sei un artista?',
          gender: 'Genere',
          facebook_profile_url: 'Profilo Facebook',
          twitter_profile_url: 'Profilo X',
          dribbble_profile_url: 'Profilo Dribbble',
          youtube_url: 'Canale YouTube',
          whatsapp: 'Numero di telefono whatsapp',
          other_details: 'Altri dettagli su di te',
        },
      },
      UsersLargeList: {
        followers: 'Followers',
        following: 'Seguiti',
      },
    },
    BoxNovita: {
      button: 'Ho Capito',
    },
    ReadMoreText: {
      readMore: 'Leggi Altro...',
      readLess: 'Riduci',
    },
    ServerMaintenance: {
      title: 'È in arrivo la nuova versione del nostro Social!',
      subtitle: 'Dantebus Social è in aggiornamento!',
      description:
        'Ancora poche ore e sarà disponibile una nuova versione del primo Social Network per gli Artisti.',
    },
    SuggestUserIntro: {
      title: 'Suggerimenti per te',
      description: {
        part1:
          'Sembra che tu non stia ancora seguendo artisti su Dantebus! Ecco alcuni suggerimenti per te.',
        part2: 'Seleziona {count} artisti per proseguire',
      },
    },
    TagInterest: {
      title: 'Seleziona i tuoi interessi artistici',
      selectInterest: 'Seleziona uno o più interessi',
      selectedTitle: 'Sei interessato a...',
      showMore: 'Mostra altri',
    },
  },
  layouts: {
    Authentication: {
      ForgotPasswordDesktop: {
        title: 'Password dimenticata?',
        instructions:
          'Inserisci il tuo indirizzo email per avviare la procedura di recupero',
        emailPlaceholder: 'Email del tuo account',
        nextButton: 'Avanti',
        backButton: 'Indietro',
        emailSentMessage:
          "Ti è stata inviata un'email con un link per aggiornare la tua password.",
        emailFailedMessage:
          'Non è possibile recuperare la password. Riprovare più tardi.',
      },
      ForgotPasswordMobile: {
        title: 'Password dimenticata?',
        instructions:
          'Inserisci il tuo indirizzo email per avviare la procedura di recupero',
        emailPlaceholder: 'Email del tuo account',
        nextButton: 'Avanti',
        backButton: 'Indietro',
        emailSentMessage:
          "Ti è stata inviata un'email con un link per aggiornare la tua password.",
        emailFailedMessage:
          'Non è possibile recuperare la password. Riprovare più tardi.',
      },
      LoginDesktop: {
        welcomeBack: 'Piacere di rivederti',
        instructions:
          'Inserisci il tuo indirizzo email e la tua password per proseguire',
        emailPlaceholder: 'Email',
        passwordPlaceholder: 'Password',
        forgotPassword: 'Password dimenticata?',
        loginButton: 'Accedi',
        socialLogin: 'Accedi con',
        registerButton: 'Registrati con email',
      },
      LoginMobile: {
        welcomeBack: 'Piacere di rivederti!',
        instructions:
          'Inserisci il tuo indirizzo email e la tua password per proseguire',
        emailPlaceholder: 'Email',
        passwordPlaceholder: 'Password',
        forgotPassword: 'Password dimenticata?',
        loginButton: 'Accedi',
        socialLogin: 'Accedi con',
        registerButton: 'Registrati con email',
      },
      RegistrationDesktop: {
        createAccount: 'Crea un account',
        instructions:
          'Inserisci i tuoi dati ed accedi al Social Network per artisti',
        usernamePlaceholder: 'Nome utente',
        emailPlaceholder: 'Email',
        passwordPlaceholder: 'Password',
        passwordWeak: 'La password risulta troppo debole',
        passwordRequirement1: 'Minimo 8 caratteri.',
        passwordRequirement2: 'Almeno un carattere MAIUSCOLO.',
        passwordRequirement3: 'Almeno un carattere minuscolo.',
        passwordRequirement4: 'Almeno un numero.',
        passwordRequirement5: 'Almeno un carattere speciale.',
        signupButton: 'Registrati',
        termsAndConditions:
          'Cliccando su "Registrati", accetti le nostre <a href="https://dantebus.com/note-legali" target="_blank">Condizioni</a> e confermi di aver letto la nostra <a href="https://www.iubenda.com/privacy-policy/60273184" target="_blank">Informativa sulla privacy</a>, inclusa la <a href="https://www.iubenda.com/privacy-policy/60273184/cookie-policy" target="_blank">Normativa sull\'uso dei cookie</a>.',
        signupWith: 'Registrati con',
        loginButton: 'Accedi',
      },
      RegistrationMobile: {
        createAccount: 'Crea un account',
        instructions:
          'Inserisci i tuoi dati ed accedi al Social Network per artisti',
        usernamePlaceholder: 'Nome utente',
        emailPlaceholder: 'Email',
        passwordPlaceholder: 'Password',
        passwordWeak: 'La password risulta troppo debole',
        passwordRequirement1: 'Minimo 8 caratteri.',
        passwordRequirement2: 'Almeno un carattere MAIUSCOLO.',
        passwordRequirement3: 'Almeno un carattere minuscolo.',
        passwordRequirement4: 'Almeno un numero.',
        passwordRequirement5: 'Almeno un carattere speciale.',
        signupButton: 'Registrati',
        termsAndConditions:
          'Cliccando su "Registrati", accetti le nostre <a href="https://dantebus.com/note-legali" target="_blank">Condizioni</a> e confermi di aver letto la nostra <a href="https://www.iubenda.com/privacy-policy/60273184" target="_blank">Informativa sulla privacy</a>, inclusa la <a href="https://www.iubenda.com/privacy-policy/60273184/cookie-policy" target="_blank">Normativa sull\'uso dei cookie</a>.',
        signupWith: 'Registrati con',
        loginButton: 'Accedi',
      },
    },
    LeftBar: {
      following: 'Seguiti',
      forYou: 'Per te',
    },
    TopBar: {
      searchPlaceholder: 'Cerca qui...',
      newPost: 'Nuova Opera',
      myWall: 'La mia bacheca',
      portfolio: 'Portfolio',
      new: 'Novità',
      myProfile: 'Il mio profilo',
      myGroups: 'I miei gruppi',
      myFavorites: 'I miei preferiti',
      reportProblem: 'Segnala un problema',
      logout: 'Esci',
    },
    BottomBar: {
      myWall: 'La mia bacheca',
      portfolio: 'Portfolio',
      myProfile: 'Il mio profilo',
      myGroups: 'I miei gruppi',
      reportProblem: 'Segnala un problema',
      logout: 'Esci',
    },
    HomeDesktop: {
      subtitle: 'Entra nel social network degli artisti',
      loginButton: 'Accedi',
      registerButton: 'Registrati',
    },
    HomeMobile: {
      subtitle: 'Entra nel social network degli Artisti!',
      loginButton: 'Accedi',
      registerButton: 'Registrati',
      versionLabel: 'Versione:',
    },
    inputs: {
      PrivacySelector: {
        cancelText: 'Annulla',
        all: 'Tutti',
        following: 'Chi mi segue',
        onlyMe: 'Solo io',
      },
    },
    Intro: {
      welcomeMessage:
        "Benvenuto in Dantebus, il social network italiano interamente dedicato all'Arte!",
      initialConfig: 'Configurazione Iniziale',
      completeProfile: 'Configura e completa il tuo Profilo',
      selectCover: 'Seleziona Copertina',
      changeCoverImage: 'Cambia immagine di copertina',
      back: 'Indietro',
      exit: 'Esci',
      next: 'Avanti',
      finish: 'Fine',
    },
    LoggedInLayout: {
      newMessageFrom: 'Hai ricevuto un messaggio da',
      view: 'Visualizza',
      ok: 'OK',
    },
  },
  mixins: {
    appInterface: {
      loading: 'Caricamento in corso...',
      wantLogout: 'Vuoi Uscire?',
      wantClose: 'Vuoi Uscire dall App?',
      NO: 'NO',
      YES: 'SI',
    },
    chatInterface: {
      confirmationHeader: 'Confermi?',
      confirmationMessage:
        'La conversazione verrà eliminata per entrambi i partecipanti alla chat.',
      cancelButton: 'Annulla',
      deleteButton: 'Elimina',
      deletingMessage: 'Eliminazione...',
    },
    docEditor: {
      write: 'Scrivi...',
    },
    notificationInterface: {
      newFollower: "L'utente <b>{fromUserName}</b> ha iniziato a seguirti",
      subscriptionRequest:
        "L'utente <b>{fromUserName}</b> ha richiesto l'adesione al gruppo <b>{subjectName}</b>",
      comment: "L'utente <b>{fromUserName}</b> ha commentato un tuo {name}",
      like: "L'utente <b>{fromUserName}</b> ha messo mi piace {name}",
      newPost: "L'utente <b>{fromUserName}</b> ha pubblicato un nuovo post",
      commentTag:
        "L'utente <b>{fromUserName}</b> ti ha taggato in un commento {name}",
      newSubscription:
        "L'utente <b>{fromUserName}</b> si è iscritto al gruppo <b>{subjectName}</b>",
      reply: "L'utente <b>{fromUserName}</b> ha risposto a un tuo {name}",
      groupRequestAccepted:
        'La tua richiesta di adesione al gruppo <b>{subjectName}</b> è stata accettata',
      groupNewPermissionAdmin: 'Ora sei admin del gruppo <b>{subjectName}</b>',
      groupNewPermissionMod:
        'Ora sei moderatore del gruppo <b>{subjectName}</b>',
      groupUnban: 'Puoi di nuovo accedere al gruppo <b>{subjectName}</b>',
      groupBan:
        'Hai ricevuto un ban dal gruppo <b>{subjectName}</b>. Non potrai più accederci.',
      post: 'post',
      group: 'gruppo',
      comment1: 'commento',
    },
  },
  pages: {
    ChatList: {
      chat: 'Chat',
      newMessage: 'Nuovo messaggio',
      writing: 'Sta scrivendo...',
      attachment: 'Allegato',
      attachments: 'Allegati',
      chatDeleted: 'Chat con {username} eliminata con successo',
      userEliminated: 'Utente Eliminato',
    },
    SingleChat: {
      selectUser: 'Seleziona un utente per iniziare!',
      or: 'oppure',
      startChat: 'Avvia una chat ora!',
      writing: 'sta scrivendo...',
      writeMessage: 'Scrivi un messaggio...',
      blocked: 'Conversazione Bloccata',
      chatDeleted: 'Chat eliminata con successo',
      chatDeletedWarning: 'La chat è stata eliminata',
      sendError: 'Impossibile inviare il messaggio: ',
      unexpectedError:
        'Impossibile inviare il messaggio: Errore inaspettato. Riprova più tardi.',
    },
    DantebusEdizioni: {
      FormManoscritto: {
        whyChoose: 'Perché scegliere Dantebus Edizioni',
        features: {
          0: {
            title: 'Prefazione critica attenta',
            description:
              "Offriamo una prefazione critica scritta dal nostro Comitato Scientifico che, con un'analisi approfondita dell'opera, quasi come a volerla mettere in controluce, presenta al pubblico i punti cardini della tua poetica, i risvolti filosofici e le tematiche da te trattate.",
          },
          1: {
            title: 'Editing professionale',
            description:
              "Il nostro team di esperti editor lavora meticolosamente sul tuo manoscritto o catalogo d'arte (fotografia o pittura) per perfezionare stile, struttura e contenuto, garantendo la massima qualità del testo.",
          },
          2: {
            title: 'Impaginazione accurata',
            description:
              "Curiamo ogni dettaglio dell'impaginazione per assicurare una presentazione visivamente accattivante e una lettura fluida, rispettando gli standard professionali del settore editoriale.",
          },
          3: {
            title: 'Copertina realizzata da un artista digitale',
            description:
              "Il nostro talentuoso artista digitale crea copertine uniche e accattivanti, progettate per catturare l'essenza della tua opera e attirare l'attenzione dei lettori.",
          },
          4: {
            title: 'ISBN e distribuzione globale',
            description:
              "Assegniamo un codice ISBN unico al tuo libro e lo distribuiamo in formato e-book su piattaforme come Mondadori, Feltrinelli, IBS, Amazon e Apple. Inoltre, la versione cartacea sarà disponibile nelle nostre gallerie d'arte a Roma (Via Margutta 38) e Firenze (Via dello Studio 22), aumentando la visibilità della tua opera.",
          },
          5: {
            title: "Vendita nelle nostre Librerie/Gallerie d'Arte",
            description:
              "Il tuo libro sarà disponibile nelle nostre gallerie d'arte situate nel centro storico di Roma (Via Margutta 38) e a Firenze (Via dello Studio 22, zona Duomo), offrendo un punto di vendita prestigioso per il tuo lavoro.",
          },
        },
      },
    },
    ForgotPassword: {
      UpdatePassword: {
        newPasswordTitle: 'Nuova Password',
        validCodeMessage: 'Codice Valido, inserire qui la nuova password',
        newPasswordPlaceholder: 'Nuova Password',
        confirmPasswordPlaceholder: 'Conferma Password',
        updateButton: 'Aggiorna Password',
        passwordRules: {
          title: 'La Password deve essere formata da:',
          rule1: '- Lunghezza minima 8 caratteri;',
          rule2: '- Almeno un carattere Maiuscolo;',
        },
        successMessage: 'Password Aggiornata con successo',
        errorMessage:
          'Non è stato possibile aggiornare la password. Riprovare più tardi',
        weakPasswordMessage:
          'Password Debole.\nInserire almeno un carattere MAIUSCOLO,\nun carattere minuscolo,\nun numero,\nun carattere speciale',
        passwordMismatchMessage: 'Le password non coincidono',
      },
      ValidateCode: {
        verifyCodeTitle: 'Verifica Codice',
        successEmailMessage:
          "E' stata inviata con successo una email con il codice. Inserire qui per continuare.",
        backButton: 'Indietro',
      },
    },
    Group: {
      GroupHeader: {
        changeCoverImageButton: 'Cambia immagine di copertina',
        groupPrivacy: {
          public: 'Pubblico',
          private: 'Privato',
        },
        members: 'Iscritti',
        subscribePublic: 'Iscriviti al gruppo',
        subscribePrivate: 'Richiedi Adesione',
        unsubscribe: 'Esci dal gruppo',
        editGroupButton: 'Modifica Gruppo',
        dashboard: 'Bacheca',
        membersLabel: 'Membri',
        privateGroupMessage:
          "Il gruppo è privato, richiedi l'adesione per partecipare al gruppo e visualizzare i posts",
        shareTitle: 'Guarda il gruppo {name}',
        shareText: 'Guarda il gruppo di {name}',
        shareDialogTitle: 'Condividi con i tuoi amici',
      },
      MembersGroup: {
        members: 'Iscritti',
        requests: 'Richieste',
        banned: 'Bloccati',
      },
      PostGroup: {
        postYourWork:
          'Ciao {name}, clicca qui per pubblica una tua opera sul gruppo',
        bannedMessage: 'Sei stato bannato da questo gruppo.',
      },
    },
    Profile: {
      AdvertiserProfile: {
        headings: {
          campaign: 'Campagna',
          status: 'Stato',
          dailyPublications: 'Pubblicazioni giornaliere',
          impressions: 'Impressions',
          clicks: 'Clicks',
        },
        placeholders: {
          dailyPublications: 'Pubblicazioni giornaliere',
        },
        loading: 'Caricamento...',
      },
      InfoProfile: {
        updatePassword: 'Aggiorna la Password',
        updateEmail: "Aggiorna l'indirizzo Email",
        linkSocialAccounts: 'Account Social',
        linkSocialDescription:
          'Collega i tuoi account social Google, Facebook{apple} per velocizzare il login su Dantebus Social. Nessun post verrà pubblicato a tuo nome sui tuoi profili social.',
        andApple: ' e Apple',
        manageAccount: 'Gestione Account',
        deleteAccount: 'Elimina il mio account',
        downloadInformation: 'Scarica le mie informazioni',
        logout: 'Esci',
        version: 'Ver: {version}',
        downloadInfoMessage:
          "Hai richiesto il download dei tuoi dati. Entro pochi minuti sarà inviata un'email al tuo indirizzo contenente un link per scaricarle.",
        accountDeletionMessage:
          'Vuoi eliminare il tuo account? Tutti i tuoi posts saranno eliminati definitivamente, potrai accedere di nuovo a Dantebus soltanto creando un nuovo account. Vuoi procedere?',
        no: 'NO',
        yes: 'SI',
        ok: 'OK',
        language: 'Lingua',
      },
      LinkedAccount: {
        facebook: 'Facebook',
        google: 'Google',
        apple: 'Apple',
        link: 'Collega',
        linked: 'Collegato',
        unlink: 'Esci',
      },
      ProfileHeader: {
        buttons: {
          changeCoverImage: 'Cambia immagine di copertina',
          writeMessage: 'Scrivi un messaggio',
          unblock: 'Sblocca',
          block: 'Blocca',
          shadowBan: 'Shadow ban',
          banDefinitive: 'Ban definitivo',
          share: 'Condividi',
          banUser: 'Banna Utente',
          report: 'Segnala',
        },
        labels: {
          followers: 'Followers',
          following: 'Seguiti',
          posts: 'Posts',
          comments: 'Commenti',
          followersCount: 'Followers',
          followingCount: 'Seguiti',
          sections: {
            feed: 'Bacheca',
            portfolio: 'PortFolio',
            profile: 'Profilo',
            groups: 'Gruppi',
            bookmarked: 'Preferiti',
            advertising: 'Advertising',
            adv: 'Adv',
          },
        },
        messages: {
          accountFollowedBy: 'Account seguito da: ',
          blockedUser:
            'Hai bloccato questo utente. Clicca in alto per sbloccare',
        },
        actionSheet: {
          header: 'Opzioni',
        },
        share: {
          title: 'Guarda il profilo di {name}',
          text: 'Guarda il profilo di {name}',
          dialogTitle: 'Condividi con i tuoi amici',
        },
      },
    },
    ChatView: {
      noActiveChats: 'Non hai nessuna chat attiva',
      startChatNow: 'Avvia una chat ora!',
    },
    MobileSearch: {
      searchTitle: 'Cerca su Dantebus Social',
    },
    ProfilePage: {
      profileUnavailable: 'Profilo non disponibile',
      ok: 'OK',
    },
    Wall: {
      suggestionsForYou: 'Suggerimenti per te',
      suggestionText1:
        'Sembra che tu non stia ancora seguendo artisti su Dantebus! Ecco alcuni suggerimenti per te.',
      suggestionText2: 'Seleziona {count} artisti per proseguire',
      newPosts: 'Nuovi posts',
    },
  },
  App: {
    emailNotVerified: 'Il tuo indirizzo email non è verificato',
    retryInSeconds: 'Puoi riprovare tra {count} secondi',
    sendVerificationEmail: 'Invia la mail di verifica',
    verifyMyEmail: 'Verifica la mia email',
    profileUnavailable: 'Profilo non disponibile',
    ok: 'OK',
    verificationEmailSent: 'Email di verifica inviata con successo.',
    errorSendingVerificationEmail:
      'Si è verificato un errore, riprova tra qualche minuto.',
    appName: 'Dantebus',
    newUpdateAvailable: "E' disponibile un nuovo aggiornamento.<br>{changelog}",
    ignore: 'Ignora',
    update: 'Aggiorna',
    updating: 'Aggiornamento in corso...',
  },
};
