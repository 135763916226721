import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = () => ({
  new_posts: [],
  items: {},
  items_grouped: {},
  latestPage: {},
  ad_posts: [],
  currentWall: {
    type: null,
    type_id: null,
  },
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
