import { findIndex } from 'lodash-es';

export default {
    //state
    setAll(state, items){
        state.items = items;
    },
    setAllLikes(state, items){
        state.likes = items;
    },
    addItem(state, {item, parent_id}){
        if ( parent_id !== null ){
            const parent_comment_index = findIndex(state.items, (item) => item.comment.identity === parent_id);
            if ( parent_comment_index >= 0 ){
                state.items[parent_comment_index].replys.unshift(item);
                return true;
            }

            return false;
        }

        state.items.unshift(item);
    },
    updateItem(state, comment){
        let comment_index = findIndex(state.items, (o) => o.comment.identity === comment.comment.identity);
        if ( comment_index >= 0 ){
            state.items[comment_index] = comment;
            return true;
        }

        const parent_comment_index = findIndex(state.items, (item) => {
            const replyIndex = findIndex(item.replys, (o) => o.comment.identity === comment.comment.identity);
            return replyIndex >= 0;
        })

        if ( parent_comment_index < 0 ){ console.log(0); return false;}

        const reply_comment_index = findIndex(
            state.items[parent_comment_index].replys,
            (o) => o.comment.identity === comment.comment.identity
        );

        if ( reply_comment_index < 0 ){ console.log(1); return false; }

        state.items[parent_comment_index].replys[reply_comment_index] = comment;
        return true;
    },
    deleteItem(state, id){
        const comment_index = findIndex(state.items, (item) => item.comment.identity === id);
        if ( comment_index >= 0 ){
            state.items.splice(comment_index, 1);
            return true;
        }

        const parent_comment_index = findIndex(state.items, (item) => {
            const replyIndex = findIndex(item.replys, (o) => o.comment.identity === id);
            return replyIndex >= 0;
        })

        if ( parent_comment_index < 0 ) return false;

        const reply_comment_index = findIndex(
            state.items[parent_comment_index].replys,
            (o) => o.comment.identity === id
        );

        if ( reply_comment_index < 0 ) return false;

        state.items[parent_comment_index].replys.splice(reply_comment_index, 1);
        return true;
    },
    toggleLike(state, {id, like_added}){
        const comment_index = findIndex(state.items, (item) => item.comment.identity === id);
        if ( comment_index >= 0 ){
            state.items[comment_index].cu_has_liked = like_added;
            if ( like_added ) state.items[comment_index].likes_count += 1;
            else state.items[comment_index].likes_count -= 1;

            return true;
        }

        const parent_comment_index = findIndex(state.items, (item) => {
            const replyIndex = findIndex(item.replys, (o) => o.comment.identity === id);
            return replyIndex >= 0;
        })

        if ( parent_comment_index < 0 ) return false;

        const reply_comment_index = findIndex(
            state.items[parent_comment_index].replys,
            (o) => o.comment.identity === id
        );

        if ( reply_comment_index < 0 ) return false;

        state.items[parent_comment_index].replys[reply_comment_index].cu_has_liked = like_added;
        if ( like_added ) state.items[parent_comment_index].replys[reply_comment_index].likes_count += 1;
        else state.items[parent_comment_index].replys[reply_comment_index].likes_count -= 1;

        return true;
    }
}
