<template>
  <ion-content>
    <ion-header mode="md" class="header">
      <ion-toolbar class="modal-toolbar" mode="md">
        <ion-title>{{ $t('components.Popups.ReportNode.report', { nodeName }) }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="close">
            <ion-icon slot="icon-only" :icon="closeSharp"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <div class="ion-padding-start ion-padding-end ion-padding-bottom">
      <ion-item fill="outline" class="ion-margin-top">
        <ion-label position="floating">{{ $t('components.Popups.ReportNode.motivation') }}</ion-label>
        <ion-select id="selectMotivation" v-model="report_model.motivation" cancel-text="{{ $t('components.Popups.ReportNode.cancel') }}">
          <ion-select-option value="annoys_me">{{ $t('components.Popups.ReportNode.options.annoys_me') }}</ion-select-option>
          <ion-select-option value="copyright_infringement">{{ $t('components.Popups.ReportNode.options.copyright_infringement') }}</ion-select-option>
          <ion-select-option value="spam">{{ $t('components.Popups.ReportNode.options.spam') }}</ion-select-option>
          <ion-select-option value="inappropriate">{{ $t('components.Popups.ReportNode.options.inappropriate') }}</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item fill="outline" class="ion-margin-top">
        <ion-label position="floating">
          {{ $t('components.Popups.ReportNode.description') }} <small>({{ $t('components.Popups.ReportNode.optional') }})</small>
        </ion-label>
        <ion-textarea v-model="report_model.description" rows="13" />
      </ion-item>
    </div>
    <ion-footer>
      <ion-toolbar mode="md">
        <ion-buttons slot="end">
          <ion-button color="primary" @click="store" :disabled="saving">
            <ion-spinner v-if="saving" />
            <span v-else>{{ $t('components.Popups.ReportNode.send') }}</span>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </ion-content>
</template>

<script>
import { closeSharp } from "ionicons/icons";
import appInterface from "../../mixins/appInterface";
import axios from "../../boot/axios";

export default {
  name: "ReportNode",
  props: {
    nodeName: {
      type: String,
      default: "",
    },
    nodeId: {
      type: Number,
      required: true,
    },
    close: {
      type: Function
    }
  },
  mixins: [appInterface],
  setup: () => ({ closeSharp }),
  data: () => ({
    saving: false,
    isOpen: false,
    report_model: {
      node_id: null,
      motivation: null,
      description: null,
    },
  }),
  mounted() {
    this.report_model.node_id = this.nodeId;
    this.report_model.motivation = null;
    this.report_model.description = null;
  },
  methods: {
    async store() {
      if (!this.report_model.motivation) {
        this.showToast(this.$t('components.Popups.ReportNode.error_select_motivation'));
        return;
      }

      this.saving = true;
      try {
        const response = await axios.post("/report", this.report_model);
        if (response.data.status === true) {
          this.showToast(this.$t('components.Popups.ReportNode.success_report'), {
            color: "success",
          });
          this.close();
        } else {
          this.showToast(this.$t('components.Popups.ReportNode.error_report'));
        }
      } catch (error) {
        this.showToast(this.$t('components.Popups.ReportNode.error_report'));
      }
      this.saving = false;
    },
  },
};
</script>

<style scoped>
.header {
  /* Add any necessary styles here */
}
.modal-toolbar {
  /* Add any necessary styles here */
}
</style>
