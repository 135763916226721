/* eslint-disable no-console */
/* eslint-disable no-case-declarations */
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { SignInWithApple } from '@capacitor-community/apple-sign-in';
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signInWithCredential,
  initializeAuth,
  OAuthProvider,
  linkWithPopup,
  unlink,
  linkWithCredential,
  browserSessionPersistence,
  browserPopupRedirectResolver,
} from 'firebase/auth';
import { isPlatform } from '@ionic/vue';
import { getApp } from 'firebase/app';
import firebaseErrors from '../../boot/firebaseAuthMessages.json';

export async function nativeLogin(provider_name) {
  let provider_credential;
  let firebase_credential;
  switch (provider_name) {
    case 'facebook':
      await FacebookLogin.logout();
      provider_credential = await FacebookLogin.login({
        permissions: ['public_profile', 'email'],
      });
      firebase_credential = FacebookAuthProvider.credential(
        provider_credential.accessToken.token
      );
      break;
    case 'google':
      await GoogleAuth.initialize();
      await GoogleAuth.signOut();
      provider_credential = await GoogleAuth.signIn();
      console.log('google', provider_credential);
      firebase_credential = GoogleAuthProvider.credential(
        provider_credential.authentication.idToken
      );
      break;
    case 'apple':
      provider_credential = await SignInWithApple.authorize({
        scopes: 'email name',
      });
      firebase_credential = new OAuthProvider('apple.com').credential({
        idToken: provider_credential.response.identityToken,
      });
      break;
    default:
      throw new Error('Invalid provider_name (' + provider_name + ')');
  }

  console.log('provider_credential ' + provider_name, provider_credential);
  console.log('firebase_credential', firebase_credential);

  const auth = getNativeAuth();
  console.log(auth);
  try {
    const response = await signInWithCredential(auth, firebase_credential);
    console.log('firebase auth response', response);
    return { status: true, response };
  } catch (e) {
    console.log(e.code);
    const error_message = firebaseErrors[this.$i18n.locale][e.code]
      ? firebaseErrors[this.$i18n.locale][e.code]
      : firebaseErrors.else.replace('%error_code%', e.code);
    console.log('nativeLogin', error_message);
    return {
      status: false,
      error_code: e.code,
    };
  }
}

export async function webLogin(provider_name) {
  let provider_instance;
  switch (provider_name) {
    case 'facebook':
      provider_instance = new FacebookAuthProvider();
      break;
    case 'google':
      provider_instance = new GoogleAuthProvider();
      break;
    case 'apple':
      provider_instance = new OAuthProvider('apple.com');
      break;
    default:
      throw new Error('Invalid provider_name (' + provider_name + ')');
  }

  const auth = getAuth();
  auth.languageCode = 'it';

  try {
    let response;
    if (isPlatform('mobile')) {
      console.log('Attivazione Redirect');
      response = await signInWithRedirect(auth, provider_instance);
    } else {
      console.log('Attivazione Popup');
      response = await signInWithPopup(auth, provider_instance);
    }
    console.log('firebase auth response', response);
    return { status: true, response };
  } catch (e) {
    console.log(e.code);
    const error_message = firebaseErrors[this.$i18n.locale][e.code]
      ? firebaseErrors[this.$i18n.locale][e.code]
      : firebaseErrors.else.replace('%error_code%', e.code);
    console.log('Social', error_message);
    return {
      status: false,
      error_code: e.code,
    };
  }
}

export function getNativeAuth() {
  if (isPlatform('android') || isPlatform('ios')) {
    const app = getApp();
    if (isPlatform('mobileweb')) {
      return initializeAuth(app, {
        persistence: browserSessionPersistence,
        popupRedirectResolver: browserPopupRedirectResolver,
      });
    } else {
      return initializeAuth(app);
    }
  } else {
    return getAuth();
  }
}

export async function linkProvider(provider_name) {
  let provider_instance;
  switch (provider_name) {
    case 'facebook':
      provider_instance = new FacebookAuthProvider();
      break;
    case 'google':
      provider_instance = new GoogleAuthProvider();
      break;
    case 'apple':
      provider_instance = new OAuthProvider('apple.com');
      break;
    default:
      throw new Error('Invalid provider_name (' + provider_name + ')');
  }
  const auth = getAuth();
  try {
    const response = await linkWithPopup(auth.currentUser, provider_instance);
    let credential;
    switch (provider_name) {
      case 'facebook':
        credential = FacebookAuthProvider.credentialFromResult(response);
        if (credential) {
          return {
            status: true,
            credential,
          };
        }
        break;
      case 'google':
        credential = GoogleAuthProvider.credentialFromResult(response);
        if (credential) {
          return {
            status: true,
            credential,
          };
        }
        break;
      case 'apple':
        if (response.user) {
          return {
            status: true,
            credential,
          };
        }
        break;
      default:
        console.info('provider_name', provider_name);
        return {
          status: false,
          error_message: 'Errore del server (Err. code 1).',
        };
    }
  } catch (response) {
    console.error(response);
    const error_message = firebaseErrors[this.$i18n.locale][response.code]
      ? firebaseErrors[this.$i18n.locale][response.code]
      : firebaseErrors.else.replace('%error_code%', response.code);
    console.log('Social', error_message);
    return {
      status: false,
      error_message,
    };
  }
}

export async function linkNativeProvider(provider_name) {
  let provider_credential;
  let firebase_credential;
  let credential;
  switch (provider_name) {
    case 'facebook':
      await FacebookLogin.logout();
      provider_credential = await FacebookLogin.login({
        permissions: ['public_profile', 'email'],
      });
      firebase_credential = FacebookAuthProvider.credential(
        provider_credential.accessToken.token
      );
      break;
    case 'google':
      await GoogleAuth.initialize();
      await GoogleAuth.signOut();
      provider_credential = await GoogleAuth.signIn();
      console.log('google', provider_credential);
      firebase_credential = GoogleAuthProvider.credential(
        provider_credential.authentication.idToken
      );
      break;
    case 'apple':
      provider_credential = await SignInWithApple.authorize({
        scopes: 'email name',
      });
      firebase_credential = new OAuthProvider('apple.com').credential({
        idToken: provider_credential.response.identityToken,
      });
      break;
    default:
      throw new Error('Invalid provider_name (' + provider_name + ')');
  }

  const auth = getNativeAuth();
  console.log(auth);

  try {
    const response = await linkWithCredential(
      auth.currentUser,
      firebase_credential
    );
    console.log(response);
    switch (provider_name) {
      case 'facebook':
        credential = FacebookAuthProvider.credentialFromResult(response);
        if (credential) {
          return {
            status: true,
            credential,
          };
        }
        break;
      case 'google':
        credential = GoogleAuthProvider.credentialFromResult(response);
        if (credential) {
          return {
            status: true,
            credential,
          };
        }
        break;
      case 'apple':
        if (response.user) {
          return {
            status: true,
            credential,
          };
        }
        break;
      default:
        return {
          status: false,
          error_message: 'Errore del server (Err. code 2)',
        };
    }
  } catch (response) {
    console.log(response.code);
    const error_message = firebaseErrors[this.$i18n.locale][response.code]
      ? firebaseErrors[this.$i18n.locale][response.code]
      : firebaseErrors.else.replace('%error_code%', response.code);
    console.log('Social', error_message);
    return {
      status: false,
      error_message,
    };
  }
}

export async function unlinkProvider(provider_name) {
  let provider_instance;
  switch (provider_name) {
    case 'facebook':
      provider_instance = 'facebook.com';
      break;
    case 'google':
      provider_instance = 'google.com';
      break;
    case 'apple':
      provider_instance = 'apple.com';
      break;
    default:
      throw new Error('Invalid provider_name (' + provider_name + ')');
  }
  const auth = getAuth();
  try {
    const response = await unlink(auth.currentUser, provider_instance);
    return {
      status: true,
      response,
    };
  } catch (response) {
    const error_message = firebaseErrors[this.$i18n.locale][response.code]
      ? firebaseErrors[this.$i18n.locale][response.code]
      : firebaseErrors.else.replace('%error_code%', response.code);
    console.log('Social', error_message);
    return {
      status: false,
      error_message,
    };
  }
}

export function getProviderDataConnected() {
  const auth = getNativeAuth();
  if (auth.currentUser) {
    console.log('getProviderDataConnected', auth.currentUser);
  }
  return auth.currentUser !== null ? auth.currentUser.providerData : [];
}
