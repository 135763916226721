<template>
  <div class="message-card" :style="isMobile ? 'margin-left: 0px; margin-right: 0px' : 'margin: unset'">
    <div style="display: flex; flex: 1; align-items: center">
      <ion-icon :icon="checkType" color="primary" class="icon_alert"></ion-icon>
      <ion-label color="black" class="ion-text-wrap" v-html="message"></ion-label>
    </div>
  </div>
</template>

<script>
import { informationCircleOutline, alertCircleOutline } from "ionicons/icons";
import appInterface from "../mixins/appInterface";

export default {
  name: "CardMessage",
  props: ["type", "message"],
  mixins: [appInterface],
  setup: () => ({
    informationCircleOutline,
    alertCircleOutline,
  }),
  computed: {
    checkType() {
      switch (this.type) {
        case "info":
          return this.informationCircleOutline;
        case "alert":
          return this.alertCircleOutline;
      }
      return this.informationCircleOutline;
    },
  },
};
</script>

<style lang="scss" scoped>
.message-card {
  background-color: hsl(0, 0%, 100%);
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #eee;
  flex: 1;
}

.icon_alert {
  margin-right: 10px;
  width: 20px;
  height: 20px;

  @media only screen and (max-width: 768px) {
    width: 50px;
  }
}
</style>
