import { Preferences } from "@capacitor/preferences";

export async function setPreferencesObject(key, value) {
  await Preferences.set({
    key,
    value
  });
}

// JSON "get" example
export async function getPreferencesObject(key) {
  const ret = await Preferences.get({ key});
  return ret.value;
}