import axios from '../../../boot/axios'

export default {
    //context
    async fetchUserList(context){
        const response = await axios.get('/chat');
        if ( response.data.status === true ){
            context.commit('setList', response.data.chats);
            context.commit('pushMedias', response.data.medias);
            context.commit('pushUsers', response.data.users);
        }
    },

    async sendMessage(context, model){
        const response = await axios.post('/chat/' + (model.user ? model.user.id : model.user_id), model);
        if ( response.data.status === true ) {
            if (response.data.medias && response.data.medias.length > 0) {
                context.commit('pushMedias', response.data.medias);
            }
        }

        return response.data;
    }
}
