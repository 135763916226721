/* eslint-disable no-unused-vars */
import { map } from 'lodash-es';
import axios from '../../../boot/axios';

const rewriteNode = (node, type) => {
  const { _id, bp_id, _labels, ...secondObject } = node;
  return {
    id: node._id,
    type,
    ...secondObject,
  };
};

export default {
  async search(context, { query, page }) {
    const result = await axios.get('/generic/search', {
      params: { query, page },
    });
    const users = map(result.data.users, (item) => {
      return rewriteNode(item, 'profile');
    });
    const groups = map(result.data.groups, (item) => {
      return rewriteNode(item, 'group');
    });
    return { users, groups };
  },

  async fetchProfile(context, { user_id }) {
    try {
      const result = await axios.get('/user/profile/' + user_id);
      if (result.data.status) {
        context.commit('storeCurrentProfile', { profile: result.data.user });
      }

      return result.data.status === false ? false : result.data.user;
    } catch (error) {
      return false;
    }
  },

  async clearCurrentProfile(context, { user_id }) {
    context.commit('clearCurrentProfile', { id: user_id });
  },
};
